const SingleAmenity = (props) => {
  return (
    <div className="singleAmenity" >
      <img src={props.img} alt="Single Amenity" />
      <p>{props.name}</p>
    </div>
  );
};

export default SingleAmenity;
