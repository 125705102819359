import React from "react";
import { Formik, Form, Field } from "formik";
import { Button, FormikInput, UploadFile, Select } from "../../../ui";
import { addWebPromotionInitialValues } from "../../../../utils/formik/initialValues";
import { addWebPromotionValidationSchema } from "../../../../utils/formik/validationSchema";
import styles from "../addNewLocationForm/style.module.scss";

const AddWebPromotionForm = (props) => {
  const categories = props.categoryData?.map((val) => ({
    value: val.id,
    label: val.name,
  }));
  return (
    <Formik
      initialValues={
        props.formInitialValues || {
          ...addWebPromotionInitialValues,
        }
      }
      validationSchema={addWebPromotionValidationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        if (props.formInitialValues) {
          const updatedDataObj = {};
          const keys = Object.keys(props.formInitialValues);
          keys.map((key) => {
            if (props.formInitialValues[key] !== values[key]) {
              return (updatedDataObj[key] = values[key]);
            }
            return false;
          });
          props.updateData({
            ...updatedDataObj,
            id: values.id,
          });
        } else {
          // console.log('check form', values)
          props.data({
            ...values,
            category: parseInt(values.category),
            sequence_number: props.nextSequence,
          });
        }
      }}
    >
      {({
        errors,
        touched,
        getFieldProps,
        setFieldValue,
        handleBlur,
        isValid,
        dirty,
      }) => (
        <Form className={styles.addLocationForm}>
          <section>
            {props.formInitialValues ? (
              <div>
                <UploadFile
                  label="Banner Image*"
                  name="image"
                  onChange={(e) =>
                    setFieldValue("image", e.currentTarget.files[0])
                  }
                  formikTouch={touched.image}
                  formikErrors={errors.image}
                  style={
                    touched.image && errors.image
                      ? { borderColor: "red" }
                      : null
                  }
                />
              </div>
            ) : (
              <div>
                <div className="selectContainer">
                  <label htmlFor="category">Category*</label>
                  <select
                    onChange={(e) => {
                      props.selectedCategory(e.target.value);
                      setFieldValue("category", e.target.value);
                    }}
                    onBlur={handleBlur("category")}
                    className={styles.categoryListSelector}
                    style={
                      touched.category && errors.category
                        ? { borderColor: "red" }
                        : null
                    }
                  >
                    <option defaultValue={""}>Select</option>
                    {categories &&
                      categories.map((option, i) => (
                        <option value={option.value} key={i}>
                          {option.label}
                        </option>
                      ))}
                  </select>
                  {touched.category && errors.category ? (
                    <span className="errorText">{errors.category}</span>
                  ) : null}
                </div>
              </div>
            )}
            <div>
              <FormikInput
                type="text"
                name="name"
                label="Promotion Name*"
                formikProps={getFieldProps("name")}
                formikTouch={touched.name}
                formikErrors={errors.name}
                style={
                  touched.name && errors.name ? { borderColor: "red" } : null
                }
              />
              {props.formInitialValues ? (
                <FormikInput
                  type="number"
                  name="sequence_number"
                  label="Sequence Number*"
                  formikProps={getFieldProps("sequence_number")}
                  formikTouch={touched.sequence_number}
                  formikErrors={errors.sequence_number}
                  style={
                    touched.sequence_number && errors.sequence_number
                      ? { borderColor: "red" }
                      : null
                  }
                />
              ) : (
                <div className="inputContainer">
                  <label htmlFor="sequence_number">Sequence Number*</label>
                  <input
                    type="number"
                    name="sequence_number"
                    disabled={true}
                    style={
                      touched.sequence_number && errors.sequence_number
                        ? { borderColor: "red" }
                        : null
                    }
                    value={props.nextSequence}
                  />
                  {touched.sequence_number && errors.sequence_number ? (
                    <span className="errorText">{errors.sequence_number}</span>
                  ) : null}
                </div>
              )}
            </div>
            <div>
              <FormikInput
                type="date"
                name="effective_date"
                label="Effective Date*"
                formikProps={getFieldProps("effective_date")}
                formikTouch={touched.effective_date}
                formikErrors={errors.effective_date}
                style={
                  touched.effective_date && errors.effective_date
                    ? { borderColor: "red" }
                    : null
                }
              />
              <FormikInput
                type="date"
                name="expiration_date"
                label="Expiration Date*"
                formikProps={getFieldProps("expiration_date")}
                formikTouch={touched.expiration_date}
                formikErrors={errors.expiration_date}
                style={
                  touched.expiration_date && errors.expiration_date
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            {props.formInitialValues ? (
              <div>
                <Select
                  name="category"
                  label="Category*"
                  formikProps={getFieldProps("category")}
                  formikTouch={touched.category}
                  formikErrors={errors.category}
                  options={categories}
                  style={
                    touched.category && errors.category
                      ? { borderColor: "red" }
                      : null
                  }
                  disabled
                />
              </div>
            ) : (
              <div>
                <UploadFile
                  label="Banner Image*"
                  name="image"
                  onChange={(e) =>
                    setFieldValue("image", e.currentTarget.files[0])
                  }
                  formikTouch={touched.image}
                  formikErrors={errors.image}
                  style={
                    touched.image && errors.image
                      ? { borderColor: "red" }
                      : null
                  }
                />
              </div>
            )}
            <div>
            <label className="material-checkbox" style={{width: '100%', fontSize: '18px'}}>
                  <Field type="checkbox" name="featured_banner" />
                  <span className="checkmark"></span>
                  Featured Banner
                </label>
            </div>
            <div>
              <Button
                type="submit"
                label={props.loading ? "Saving..." : "Save"}
                disabled={props.loading || !(isValid && dirty)}
                primary
                style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
              />
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default AddWebPromotionForm;
