import React from "react";

const Button = (props) => {
  return (
    <button
      type={props.type ? props.type : "button"}
      onClick={props.onClick}
      disabled={props.disabled || false}
      style={{
        ...props.style,
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : props.primary
          ? "var(--primaryColor)"
          : props.secondary
          ? "var(--secondaryColor)"
          : "var(--greenColor)",
      }}
      className={props.className}
    >
      {props.icon ? props.icon : null} {props.label}
    </button>
  );
};

export default Button;
