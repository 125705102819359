export const GET_API_URLS = {
  STORE_URL: "/api/admin/canco/get_store/",
  STORE_TIMING_URL: "/api/admin/canco/store_timings/",
  ADD_NEW_LOCATION_URL: "/api/admin/canco/store/",
  SUB_ADMIN_URL: "/api/admin/accounts/users/",
  PROMOTIONS_URL: "/api/admin/promotions",
  WEB_PROMOTIONS: "/api/admin/webpromotions/webpromotions/",
  WEB_PROMOTIONS_CATEGORY: "/api/admin/webpromotions/category/",
  BLOG_URL: "/api/admin/cancoblog/",
  CITIES: "/api/admin/canco/city/",
  PROVINCE: "/api/admin/canco/province/",
  MODULE: "/api/admin/accounts/module/",
  BANNER: "/api/canco/banner/",
  BANNERS: "/api/canco/banners/",
};

export const POST_API_URLS = {
  LOGIN_URL: "/api/admin/accounts/login/",
  ADD_NEW_LOCATION_URL: "/api/admin/canco/store/",
  ADD_SUB_ADMIN_URL: "/api/admin/accounts/register/",
  ADD_PROMO_URL: "/api/admin/promotions/",
  ADD_WEB_PROMOTION: "/api/admin/webpromotions/webpromotions/",
  ADD_BANNER: "/api/canco/banner/",
  ADD_BANNERS: "/api/canco/banners/",
  LOGIN_REFRESH: "/api/admin/accounts/login/refresh/",
};

export const DELETE_API_URLS = {
  DELETE_PROMO: "/api/admin/promotions/",
  DELETE_LOCATION: "/api/admin/canco/delete_store/",
};

export const TIMINGS = [
  {
    day: "",
    open_time: "",
    close_time: "",
  },
  {
    day: "",
    open_time: "",
    close_time: "",
  },
  {
    day: "",
    open_time: "",
    close_time: "",
  },
  {
    day: "",
    open_time: "",
    close_time: "",
  },
  {
    day: "",
    open_time: "",
    close_time: "",
  },
  {
    day: "",
    open_time: "",
    close_time: "",
  },
  {
    day: "",
    open_time: "",
    close_time: "",
  },
];
