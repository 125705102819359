import React from "react";
import { Formik, Form } from "formik";
import { Button, FormikInput, UploadFile } from "../../../ui";
import { addPromotionInitialValues } from "../../../../utils/formik/initialValues";
import { addPromotionValidationSchema } from "../../../../utils/formik/validationSchema";
import styles from "../addNewLocationForm/style.module.scss";

const AddNewPromotionForm = (props) => {
  return (
    <Formik
      initialValues={props.formInitialValues || addPromotionInitialValues}
      validationSchema={addPromotionValidationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        if (props.formInitialValues) {
          const updatedDataObj = {};
          const keys = Object.keys(props.formInitialValues);
          keys.map((key) => {
            if (props.formInitialValues[key] !== values[key]) {
              return (updatedDataObj[key] = values[key]);
            }
            return false;
          });
          props.updateData({ ...updatedDataObj, id: values.id });
        } else {
          props.data(values);
          console.log("send", values);
        }
      }}
    >
      {({ errors, touched, getFieldProps, setFieldValue }) => (
        <Form className={styles.addLocationForm}>
          <section>
            <div>
              <UploadFile
                label="Banner Image"
                name="banner"
                // formikProps={getFieldProps("banner")}
                onChange={(e) =>
                  setFieldValue("banner", e.currentTarget.files[0])
                }
                formikTouch={touched.banner}
                formikErrors={errors.banner}
                style={
                  touched.banner && errors.banner
                    ? { borderColor: "red" }
                    : null
                }
              />
              <UploadFile
                label="Promo Image"
                name="image"
                onChange={(e) =>
                  setFieldValue("image", e.currentTarget.files[0])
                }
                // formikProps={getFieldProps("image")}
                formikTouch={touched.image}
                formikErrors={errors.image}
                style={
                  touched.image && errors.image ? { borderColor: "red" } : null
                }
              />
            </div>
            <div>
              <FormikInput
                type="text"
                name="name"
                label="Promo Name"
                formikProps={getFieldProps("name")}
                formikTouch={touched.name}
                formikErrors={errors.name}
                style={
                  touched.name && errors.name ? { borderColor: "red" } : null
                }
              />
              {/* <FormikInput
                type="number"
                name="price"
                label="Promo Price"
                formikProps={getFieldProps("price")}
                formikTouch={touched.price}
                formikErrors={errors.price}
                style={
                  touched.price && errors.price ? { borderColor: "red" } : null
                }
              /> */}
              <div className="inputContainer">
                <label htmlFor={"price"}>Promo Price</label>
                <input
                  type="number"
                  style={
                    touched.price && errors.price
                      ? { borderColor: "red" }
                      : null
                  }
                  {...getFieldProps("price")}
                  // step={1.00}
                />
                {touched.price && errors.price ? (
                  <span className="errorText">{errors.price}</span>
                ) : null}
              </div>
            </div>
            <div>
              <FormikInput
                type="number"
                name="multiplier"
                label="Multiplier"
                formikProps={getFieldProps("multiplier")}
                formikTouch={touched.multiplier}
                formikErrors={errors.multiplier}
                style={
                  touched.multiplier && errors.multiplier
                    ? { borderColor: "red" }
                    : null
                }
              />
              <FormikInput
                type="text"
                name="product_inclusion"
                label="Promo Detail"
                formikProps={getFieldProps("product_inclusion")}
                formikTouch={touched.product_inclusion}
                formikErrors={errors.product_inclusion}
                style={
                  touched.product_inclusion && errors.product_inclusion
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            <div>
              <FormikInput
                type="text"
                name="details"
                label="Product Detail"
                formikProps={getFieldProps("details")}
                formikTouch={touched.details}
                formikErrors={errors.details}
                style={
                  touched.details && errors.details
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            <div>
              <FormikInput
                type="date"
                name="effective_date"
                label="Effective Date"
                formikProps={getFieldProps("effective_date")}
                formikTouch={touched.effective_date}
                formikErrors={errors.effective_date}
                style={
                  touched.effective_date && errors.effective_date
                    ? { borderColor: "red" }
                    : null
                }
              />
              <FormikInput
                type="date"
                name="expiration_date"
                label="Expiration Date"
                formikProps={getFieldProps("expiration_date")}
                formikTouch={touched.expiration_date}
                formikErrors={errors.expiration_date}
                style={
                  touched.expiration_date && errors.expiration_date
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            <div>
              <Button
                type="submit"
                label={props.loading ? "Saving..." : "Save"}
                disabled={props.loading}
                primary
                style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
              />
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewPromotionForm;
