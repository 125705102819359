import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import { DELETE_API_URLS } from "../../../../utils/constant";
import styles from "../style.module.scss";
import { Button, Loader } from "../../../ui";
import { FormErrorPopup } from "../../popups";

const PromotionsTable = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  // const [isUpdatingBanner, setIsUpdatingBanner] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  /* Converting Image URL TO JS File Object */

  // const toDataURL = (url) =>
  //   fetch(url)
  //     .then((response) => response.blob())
  //     .then(
  //       (blob) =>
  //         new Promise((resolve, reject) => {
  //           const reader = new FileReader();
  //           reader.onloadend = () => resolve(reader.result);
  //           reader.onerror = reject;
  //           reader.readAsDataURL(blob);
  //         })
  //     );

  // function dataURLtoFile(dataurl, filename) {
  //   var arr = dataurl.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }

  const editPromoHandler = (data) => {
    // const imgUrlArr = [data.image, data.banner];

    // var promises = imgUrlArr.map(function (img) {
    //   return toDataURL(img).then((dataUrl) => {
    //     //  console.log('Here is Base64 Url', dataUrl)
    //     let fileData = dataURLtoFile(dataUrl, "imageName.jpg");
    //     //  console.log("Here is JavaScript File Object",fileData)
    //     return fileData;
    //   });
    // });
    // Promise.all(promises).then(function (results) {
    //   console.log("check", { ...data, image: results[0], banner: results[1] });
    // });
    navigate("/dashboard/addPromotion", {
      state: { ...data},
    });
  };

  const deletePromoHandler = (id) => {
    setIsDeleting(true);
    jwtInterceoptor
      .delete(DELETE_API_URLS.DELETE_PROMO + `${id}/`)
      .then((response) => {
        setIsDeleting(false);
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        setIsDeleting(false);
        setError(error.message);
        console.log("Error", error);
      });
  };

  // const updatePromoBannerHandler = (data, id) => {
  //   setIsUpdatingBanner(true);
  //   let use_as_banner =
  //     data === "true" ? true : data === "false" ? false : null;

  //   jwtInterceoptor
  //     .patch(POST_API_URLS.ADD_PROMO_URL + `${id}/`, {
  //       use_as_banner: !use_as_banner,
  //     })
  //     .then((response) => {
  //       setIsUpdatingBanner(false);
  //       console.log(response.data);
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       setIsUpdatingBanner(false);
  //       setError(error.message);
  //       console.log("Error", error);
  //     });
  // };

  return (
    <>
      <div className={`${styles.tableContainer} ${styles.promoTableContainer}`}>
        <fieldset id="promotion">
          <table>
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Image</th>
                <th>Promotion Name</th>
                <th>Multiplier</th>
                <th>Product Details</th>
                <th>Effective Date</th>
                <th>Expiration Date</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {props.promoData.map((data, i) => {
                return (
                  <tr key={i}>
                    {/* <td>
                      <Checkbox
                        defaultChecked={data.use_as_banner || false}
                        name="promotion"
                        onChange={(e) => {
                          updatePromoBannerHandler(e.target.value, data.id);
                        }}
                        value={data.use_as_banner || false}
                        disabled={isUpdatingBanner}
                      />
                    </td> */}
                    <td>
                      <img src={data.image} alt="Promotions" />
                    </td>
                    <td>
                      <h5>{data.name}</h5>
                    </td>
                    <td>
                      <span>{data.multiplier}x</span>
                    </td>
                    <td>
                      <p>{data.product_inclusion}</p>
                    </td>
                    <td>
                      <p>{data.effective_date}</p>
                    </td>
                    <td>
                      <p>{data.expiration_date}</p>
                    </td>
                    <td>
                      <p>${data.price}</p>
                    </td>
                    <td>
                      <div className={styles.btnContainer}>
                        <Button
                          label="Edit"
                          secondary
                          onClick={() => editPromoHandler(data)}
                        />
                        <Button
                          label={"Delete"}
                          primary
                          onClick={() => {
                            deletePromoHandler(data.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
              {/* {(isDeleting || isUpdatingBanner) && (
                <div className={styles.tableLoader}>
                  <Loader />
                </div>
              )} */}
              {isDeleting && (
                <div className={styles.tableLoader}>
                  <Loader />
                </div>
              )}
            </tbody>
          </table>
        </fieldset>
      </div>
      {error ? (
        <FormErrorPopup
          error={error}
          close={() => setError(false)}
          errorText="Oops! An error occured"
        />
      ) : null}
    </>
  );
};

export default PromotionsTable;
