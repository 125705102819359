import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
/* Importing Components */
import { Button } from "../../ui";
/* Importing Stylesheet */
import styles from "./style.module.scss";
import { GeoAlt } from "react-bootstrap-icons";

const DashboardHeader = (props) => {
  const navigate = useNavigate();

  const { userData } = useAuth();
  return (
    <>
      <header>
        <div className={styles.headerBlock}>
          <div>
            <h1>{props.pageTitle}</h1>
          </div>
          <div>
            <div className={styles.headerBtnContainer}>
              {props.pathName === "/dashboard/locations" ? (
                <>
                  <Button label={"See All"} primary className={styles.seeAll} />
                  <Button
                    label={"Add New Location"}
                    icon={<GeoAlt />}
                    onClick={() => navigate("addNewLocation")}
                  />
                </>
              ) : props.pathName === "/dashboard/subAdmin" ? (
                <Button
                  label={"Add Sub Admin"}
                  onClick={() => navigate("addSubAdmin")}
                  style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
                />
              ) : props.pathName === "/dashboard/webPromotions" ? (
                <Button
                  label={"Add Promotion"}
                  onClick={() => navigate("addWebPromotion")}
                  style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
                />
              ) : props.pathName === "/dashboard/communityCare" ? (
                <Button
                  label={"Add Post "}
                  onClick={() => navigate("addBlog")}
                  style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
                />
              ) : props.pathName === "/dashboard/banner" ? (
                <Button
                  label={"Add Banner "}
                  onClick={() => navigate("addBanner")}
                  style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
                />
              ) : null}
            </div>
            <div className={styles.profileContainer}>
              <button>
                <div className={styles.userName}>
                  <p>
                    {userData.firstName !== "" &&
                    userData.lastName !== ""
                      ? userData.firstName + " " + userData.lastName
                      : "User"}
                  </p>
                </div>
                <div className={styles.profileMenu}>
                  <ul>
                    <li
                      onClick={() => {
                        localStorage.removeItem("tokens");
                        navigate("/");
                      }}
                    >
                      Logout
                    </li>
                  </ul>
                </div>
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DashboardHeader;
