import React from "react";

const Select = (props) => {
  return (
    <div className="selectContainer">
      <label htmlFor={props.name}>{props.label}</label>
      <select id={props.name} {...props.formikProps} style={props.style} disabled={props.disabled || false}>
        <option defaultValue={""} >Select</option>
        {props.options && props.options.map((option, i) => (
            <option value={option.value} key={i}>{option.label}</option>
        ))}
      </select>
      {props.formikTouch && props.formikErrors ? (
        <span className="errorText">{props.formikErrors}</span>
      ) : null}
    </div>
  );
};

export default Select;
