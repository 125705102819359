import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import { CardWrapper } from "../../../../components/wrapper";
import { AddSubAdminForm } from "../../../../components/parts/forms";
import { POST_API_URLS, GET_API_URLS } from "../../../../utils/constant";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import { FormErrorPopup } from "../../../../components/parts/popups";

const AddSubAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const submitDataHandler = (data) => {
    setLoading(true);
    let dataObj = {};
    for (let k in data) {
      if (k === "modules") {
        dataObj[k] = data[k].map((val) => parseInt(val));
      } else {
        dataObj[k] = data[k];
      }
    }

    jwtInterceoptor
      .post(POST_API_URLS.ADD_SUB_ADMIN_URL, { ...dataObj, postal_code: "T4H" })
      .then((response) => {
        console.log(response);
        navigate("/dashboard/subAdmin");
      })
      .catch((error) => {
        // console.log('sub admin form', error);
        setLoading(false);
        if(error.response.status === 400) {
          setError(error.response.data);
        } else {
          setError(error.message);
        }
        // console.log("Error", error);
      });
  };

  const updateUserHandler = (data) => {
    // console.log(data);
    setLoading(true);
    let dataObj = {};
    for (let k in data) {
      if (k === "modules") {
        dataObj[k] = data[k].map((val) => parseInt(val));
      } else {
        dataObj[k] = data[k];
      }
    }

    jwtInterceoptor
      .patch(GET_API_URLS.SUB_ADMIN_URL + `${data.id}/`, { ...dataObj })
      .then((response) => {
        console.log(response.data);
        navigate("/dashboard/subAdmin");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403) {
          setError("You dont have permission to add sub admin.");
        } else {
          setError(error.message);
        }
        console.log("Error", error);
      });
  };

  return (
    <>
      <div className={styles.addSubAdminContainer}>
        <CardWrapper>
          <AddSubAdminForm
            updateData={updateUserHandler}
            data={submitDataHandler}
            loading={loading}
            formInitialValues={location.state ? {...location.state, password: ""} : null}
          />
        </CardWrapper>
      </div>
      {error && <FormErrorPopup error={error} close={() => setError(false)} />}
    </>
  );
};

export default AddSubAdmin;
