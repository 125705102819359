import { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtInterceoptor from "../../../api/jwtInterceptor";
/* Importing Stylesheet */
import styles from "./style.module.scss";
/* Importing Components */
import { DELETE_API_URLS } from "../../../utils/constant";
import { Button, SingleAmenity } from "../../ui";
import { FormErrorPopup } from "../popups";
import { CardWrapper } from "../../wrapper";
import { Clock } from "react-bootstrap-icons";

const LocationCard = (props) => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);

  /* Edit Location Function Call */

  const editLocationHandler = (data) => {
    // console.log('edit data', data);
    let dataObj = {};
    for (let k in data) {
      if (
        (k === "business_type" || k === "city" || k === "province") &&
        data[k] !== null
      ) {
        let val = data[k].id;
        dataObj[k] = val.toString();
        // console.log('string id', dataObj[k]);
      } else if (k === "amenities") {
        dataObj[k] = data[k].map((val) => val.id.toString());
      } else if (k === "timings" && data[k].length > 0) {
        dataObj[k] = data[k].map((val) => {
          return {
            day: [val.day.toString()],
            open_time: val.open_time,
            close_time: val.close_time,
          };
        });
      } else if (data[k] == null) {
        dataObj[k] = "";
      } else {
        dataObj[k] = data[k];
      }
    }
    // console.log("send data", dataObj);
    navigate("/dashboard/addNewLocation", { state: dataObj });
  };

  /* Delete Location Function Call */

  const deleteLocationHandler = (id) => {
    setIsDeleting(true);
    console.log(id);
    jwtInterceoptor
      .delete(DELETE_API_URLS.DELETE_LOCATION + `${id}`)
      .then((response) => {
        // console.log(response.data);
        setIsDeleting(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsDeleting(false);
        setError(error.message);
        console.log("Error", error);
      });
  };

  return (
    <>
      <CardWrapper className={styles.locationCardContainer}>
        {/* <div className={styles.locationImg}>
        <img src={NoImage} alt="Location" />
      </div> */}
        <h2>
          {props.storeName} - {props.businessType}
        </h2>
        <div className={styles.locationDataContainer}>
          <div className={styles.locationData}>
            <h4>{props.address}</h4>
            <div className={styles.mapCoordinates}>
              <h5>Longitude: {props.longitude}</h5>
              <h5>Latitude: {props.latitude}</h5>
            </div>
            <div className={styles.storesTimings}>
              {props.is24by7 ? (
                <Button
                  label="Open 24/7"
                  icon={
                    <>
                      <Clock />
                    </>
                  }
                  backgroundColor={"transparent"}
                  style={{ color: "var(--primaryColor)", padding: 0 }}
                />
              ) : (
                <div className={styles.storeInner}>
                  <Button
                    label="Click to see Timings"
                    icon={
                      <>
                        <Clock />
                      </>
                    }
                    backgroundColor={"transparent"}
                    style={{ color: "red", padding: 0 }}
                  />
                  <div className={styles.timingData}>
                    <div>
                      <p>Day</p>
                      <p>Open Time</p>
                      <p>Close Time</p>
                    </div>
                    {props.timings.map((timeData, i) => {
                      if (timeData.day === 1) {
                        return (
                          <div key={i}>
                            <p>Monday</p>
                            <p>{timeData.open_time}</p>
                            <p>{timeData.close_time}</p>
                          </div>
                        );
                      } else if (timeData.day === 2) {
                        return (
                          <div key={i}>
                            <p>Tuesday</p>
                            <p>{timeData.open_time}</p>
                            <p>{timeData.close_time}</p>
                          </div>
                        );
                      } else if (timeData.day === 3) {
                        return (
                          <div key={i}>
                            <p>Wednesday</p>
                            <p>{timeData.open_time}</p>
                            <p>{timeData.close_time}</p>
                          </div>
                        );
                      } else if (timeData.day === 4) {
                        return (
                          <div key={i}>
                            <p>Thursday</p>
                            <p>{timeData.open_time}</p>
                            <p>{timeData.close_time}</p>
                          </div>
                        );
                      } else if (timeData.day === 5) {
                        return (
                          <div key={i}>
                            <p>Friday</p>
                            <p>{timeData.open_time}</p>
                            <p>{timeData.close_time}</p>
                          </div>
                        );
                      } else if (timeData.day === 6) {
                        return (
                          <div key={i}>
                            <p>Saturday</p>
                            <p>{timeData.open_time}</p>
                            <p>{timeData.close_time}</p>
                          </div>
                        );
                      } else {
                        return (
                          <div key={i}>
                            <p>Sunday</p>
                            <p>{timeData.open_time}</p>
                            <p>{timeData.close_time}</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.amentiesContainer}>
            <h4>Amenties</h4>
            <div className={styles.amenityBlock}>
              {props.amenities.map((amenity, i) => {
                return (
                  <SingleAmenity
                    img={amenity.icon}
                    name={amenity.name}
                    key={i}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.locationBtnContainer}>
            <Button
              label="Edit"
              secondary
              onClick={() => editLocationHandler(props.storeData)}
            />
            <Button
              label={isDeleting ? "Deleting..." : "Delete"}
              disabled={isDeleting}
              primary
              onClick={() => deleteLocationHandler(props.storeId)}
            />
          </div>
        </div>
      </CardWrapper>
      {error && (
        <FormErrorPopup
          error={error}
          close={() => setError(false)}
          errorText="Oops! An error occured"
        />
      )}
    </>
  );
};

export default LocationCard;
