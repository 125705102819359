import * as Yup from "yup";

// let patternTwoDigisAfterComma = /^\d+\.\d{2}$/;

const validFileExtensions = {
  image: ["jpg", "png", "jpeg"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

export const addNewLocationValidationSchema = Yup.object().shape({
  canco_site_id: Yup.string().required("This is a required field"),
  ackroo_canco_location: Yup.string().required("This is a required field"),
  name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only Alphabets are allowed")
    .required("This is a required field"),
  city: Yup.string().required("This is a required field"),
  province: Yup.string().required("This is a required field"),
  business_type: Yup.string().required("This is a required field"),
  address: Yup.string().required("This is a required field"),
  latitude: Yup.string().required("This is a required field"),
  longitude: Yup.string().required("This is a required field"),
  // postal: Yup.string().required("This is a required field"),
});

export const addSubAdminValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only Alphabets are allowed")
    .required("This is a required field"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only Alphabets are allowed")
    .required("This is a required field"),
  email: Yup.string()
    .email("Invalid Email")
    .required("This is a required Field"),
  password: Yup.string().required("This is a required field"),
});

export const editSubAdminValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only Alphabets are allowed")
    .required("This is a required field"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only Alphabets are allowed")
    .required("This is a required field"),
  email: Yup.string()
    .email("Invalid Email")
    .required("This is a required Field"),
  // password: Yup.string().required("This is a required field"),
});

export const addPromotionValidationSchema = Yup.object().shape({
  name: Yup.string().required("This is a required field"),
  price: Yup.string()
    .required("This is a required field")
    .test("decimalPlaces", "Must have exactly 2 decimal places", (value) => {
      if (value === undefined) return true; // Allow empty input
      return /^-?\d+(\.\d{1,2})?$/.test(value.toString());
    }),
  multiplier: Yup.string().required("This is a required Field"),
  details: Yup.string().required("This is a required field"),
  product_inclusion: Yup.string().required("This is a required field"),
  effective_date: Yup.string().required("This is a required field"),
  expiration_date: Yup.string().required("This is a required field"),
  image: Yup.string().required("This is a required field"),
  banner: Yup.string().required("This is a required field"),
});

export const addWebPromotionValidationSchema = Yup.object().shape({
  name: Yup.string().required("This is a required field"),
  // effective_date: Yup.string().required("This is a required field"),
  // expiration_date: Yup.string().required("This is a required field"),
  image: Yup.string().required("This is a required field"),
  category: Yup.string().required("This is a required field"),
  // sequence_number: Yup.number("Invalid Number").required("This is a required field"),
  effective_date: Yup.date()
    .default(() => new Date())
    .required("This is a required field"),
  expiration_date: Yup.date()
    .when(
      "effective_date",
      (effective_date, schema) =>
        effective_date &&
        schema.min(
          effective_date,
          "Expiration Date cannot be less than Effective Date"
        )
    )
    .required("This is a required field"),
});

export const addBannerValidationSchema = Yup.object().shape({
  banner: Yup.mixed()
    .required("This is required field")
    .test("is-valid-type", "Upload valid image (.png, .jpg, .jpeg)", (value) =>
      isValidFileType(value && value.name.toLowerCase(), "image")
    ),
  module: Yup.string().required("This is a required field"),
});

export const editBannerValidationSchema = Yup.object().shape({
  banner: Yup.mixed().test(
    "is-valid-type",
    "Upload valid image (.png, .jpg, .jpeg)",
    (value) =>
      value
        ? isValidFileType(value && value.name?.toLowerCase(), "image")
        : true
  ),
  sequence: Yup.string().required("This is a required field"),
});

export const addBlogValidationSchema = Yup.object().shape({
  title: Yup.string().required("This is a required field"),
  content: Yup.string().required("This is a required field"),
  image: Yup.string().required("This is a required field"),
});
