import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import { FormErrorPopup } from "../../popups";
import { GET_API_URLS } from "../../../../utils/constant";
import styles from "../style.module.scss";
import { Button, Loader } from "../../../ui";

const SubAdminTable = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const editUserHandler = (data) => {
    // console.log('edit data', data);
    let dataObj = {};
    for (let k in data) {
      if (k === "modules") {
        dataObj[k] = data[k].map((val) => val.id.toString());
      } else if (
        k === "first_name" ||
        k === "last_name" ||
        k === "email" ||
        k === "address" ||
        k === "password" ||
        k === "id"
      ) {
        dataObj[k] = data[k];
      } else {
        continue;
      }
    }

    navigate("/dashboard/addSubAdmin", { state: dataObj });
  };

  const deleteUserHandler = (id) => {
    setIsDeleting(true);
    jwtInterceoptor
      .delete(GET_API_URLS.SUB_ADMIN_URL + `${id}/`)
      .then((response) => {
        console.log(response.data);
        setIsDeleting(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsDeleting(false);
        setError(error.message);
        console.log("Error", error);
      });
  };

  const updateUserStatusHandler = (data, id) => {
    // console.log(data);
    setIsUpdatingStatus(true);

    jwtInterceoptor
      .patch(GET_API_URLS.SUB_ADMIN_URL + `${id}/`, { is_active: data })
      .then((response) => {
        setIsUpdatingStatus(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsUpdatingStatus(false);
        setError(error.message);
        console.log("Error", error);
      });
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Name/Email</th>
              <th>Access</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props.subAdminData &&
              props.subAdminData.map((user, i) => {
                return (
                  <tr key={i} className={isDeleting ? "selected" : "none"}>
                    <td>
                      <h5>
                        {user.first_name} {user.last_name}
                      </h5>
                      <p>{user.email}</p>
                    </td>
                    <td>
                      {user.modules.map((module, i) => (
                        <span key={i}>{module.name},</span>
                      ))}
                    </td>
                    <td>
                      <div className="selectContainer">
                        <select
                          onChange={(e) => {
                            // console.log({is_active: e.target.value === 'false' ? false : true})
                            updateUserStatusHandler(
                              e.target.value === "false" ? false : true,
                              user.id
                            );
                          }}
                          value={user.is_active}
                          disabled={isUpdatingStatus}
                        >
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className={styles.btnContainer}>
                        <Button
                          label="Edit"
                          secondary
                          onClick={() => editUserHandler(user)}
                        />
                        <Button
                          label={"Delete"}
                          primary
                          onClick={() => {
                            deleteUserHandler(user.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            {(isDeleting || isUpdatingStatus) && (
              <div className={styles.tableLoader}>
                <Loader />
              </div>
            )}
          </tbody>
        </table>
      </div>
      {error && (
        <FormErrorPopup
          error={error}
          close={() => setError(false)}
          errorText="Oops! An error occured"
        />
      )}
    </>
  );
};

export default SubAdminTable;
