import { TIMINGS } from "../constant";

export const addNewLocationInitialValues = {
  canco_site_id: "",
  ackroo_canco_location: "",
  name: "",
  city: "",
  province: "",
  business_type: "",
  address: "",
  latitude: "",
  longitude: "",
  is24by7: false,
  active: false,
  postal_code: "",
  image: "",
  amenities: [],
  timings: TIMINGS,
};

/* Add Sub Admin form initial values */

export const addSubAdminInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  // address: '',
  password: "",
  modules: [],
};

/* Add Promotion Form initial values */

export const addPromotionInitialValues = {
  banner: "",
  image: "",
  name: "",
  multiplier: "",
  details: "",
  product_inclusion: "",
  effective_date: "",
  expiration_date: "",
  price: "",
  use_as_banner: false,
};

/* Add Web Promotion Form initial values */

export const addWebPromotionInitialValues = {
  image: "",
  name: "",
  effective_date: "",
  expiration_date: "",
  sequence_number: "",
  category: "",
  featured_banner: false,
};

export const addBannerInitialValues = {
  banner: "",
  module: "",
  featured_banner: false,
};

/* Add Blog Form initial values */

export const addBlogInitialValues = {
  title: "",
  content: "",
  image: "",
};
