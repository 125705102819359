import React from "react";
import { Formik, Form, Field } from "formik";
import { Button, FormikInput } from "../../../ui";
import { addSubAdminInitialValues } from "../../../../utils/formik/initialValues";
import {
  addSubAdminValidationSchema,
  editSubAdminValidationSchema,
} from "../../../../utils/formik/validationSchema";
import styles from "../addNewLocationForm/style.module.scss";

const AddSubAdminForm = (props) => {
  console.log("line 66", props.formInitialValues);
  return (
    <Formik
      initialValues={props.formInitialValues || addSubAdminInitialValues}
      validationSchema={
        props.formInitialValues
          ? editSubAdminValidationSchema
          : addSubAdminValidationSchema
      }
      enableReinitialize={true}
      onSubmit={(values) => {
        // console.log("subADmin", values);
        if (props.formInitialValues) {
          const updatedDataObj = {};
          const keys = Object.keys(props.formInitialValues);
          keys.map((key) => {
            if (props.formInitialValues[key] !== values[key]) {
              return (updatedDataObj[key] = values[key]);
            }
            return false;
          });
          props.updateData({ ...updatedDataObj, id: values.id });
        } else {
          props.data(values);
        }
      }}
    >
      {({ errors, touched, getFieldProps, values }) => (
        <Form className={styles.addLocationForm}>
          <section>
            <div>
              <FormikInput
                type="text"
                name="first_name"
                label="First Name"
                formikProps={getFieldProps("first_name")}
                formikTouch={touched.first_name}
                formikErrors={errors.first_name}
                style={
                  touched.first_name && errors.first_name
                    ? { borderColor: "red" }
                    : null
                }
              />
              <FormikInput
                type="text"
                name="last_name"
                label="Last Name"
                formikProps={getFieldProps("last_name")}
                formikTouch={touched.last_name}
                formikErrors={errors.last_name}
                style={
                  touched.last_name && errors.last_name
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            <div>
              <FormikInput
                type="email"
                name="email"
                label="Email"
                formikProps={getFieldProps("email")}
                formikTouch={touched.email}
                formikErrors={errors.email}
                style={
                  touched.email && errors.email ? { borderColor: "red" } : null
                }
              />
              {/* <FormikInput
                type="text"
                name="address"
                label="Location"
                formikProps={getFieldProps("address")}
                formikTouch={touched.address}
                formikErrors={errors.address}
                style={
                  touched.address && errors.address
                    ? { borderColor: "red" }
                    : null
                }
              /> */}
            </div>
            <div>
              <FormikInput
                type="text"
                name="email"
                label="Username"
                formikProps={getFieldProps("email")}
                formikTouch={touched.email}
                formikErrors={errors.email}
                disabled={true}
                style={
                  touched.email && errors.email ? { borderColor: "red" } : null
                }
              />
              <FormikInput
                type="password"
                name="password"
                label="Password"
                formikProps={getFieldProps("password")}
                formikTouch={touched.password}
                formikErrors={errors.password}
                style={
                  touched.password && errors.password
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            <div className={styles.amenitiesContainer}>
              <h3 id="checkbox-group">Access</h3>
              <div role="group" aria-labelledby="checkbox-group">
                <label className="material-checkbox">
                  <Field type="checkbox" name="modules" value={"1"} />
                  <span className="checkmark"></span>
                  Locations
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="modules" value={"2"} />
                  <span className="checkmark"></span>
                  Sub Admin
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="modules" value={"4"} />
                  <span className="checkmark"></span>
                  Community Care
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="modules" value={"5"} />
                  <span className="checkmark"></span>
                  Promotions
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="modules" value={"7"} />
                  <span className="checkmark"></span>
                  Banner
                </label>
                {/* <label className="material-checkbox">
                  <Field type="checkbox" name="modules" value={"8"} />
                  <span className="checkmark"></span>
                  Road Trip
                </label> */}
              </div>
            </div>
            <div>
              <Button
                type="submit"
                label={props.loading ? "Saving..." : "Save"}
                disabled={props.loading || values.modules.length === 0}
                primary
                style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
              />
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default AddSubAdminForm;
