import React, { useState, useRef } from "react";
import { useAuth } from "../../context/AuthProvider";
/* Importing Components */
import { Button } from "../../components/ui";
/* Importing Stylesheet */
import styles from "./style.module.scss";
/* Importing Images */
import CancoLogo from "../../assets/cancoLogo.png";

const Login = () => {
  const userEmail = useRef("");
  const userPassword = useRef("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [loader, setLoader] = useState(false);
  const { login } = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(userEmail.current.value);
    // console.log(userPassword.current.value);
    setLoader(true);
    let payload = {
      email: userEmail.current.value,
      password: userPassword.current.value,
    };
    const loginHandler = await login(payload);
    if(loginHandler.msg) {
      setErrorMsg(loginHandler.msg);
    }
    setLoader(false);
  };
  return (
    <div className={styles.loginContainer}>
      <section>
        <img src={CancoLogo} alt="Canco Logo" />
        <form onSubmit={handleSubmit}>
          <div className="inputContainer">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              ref={userEmail}
            />
          </div>
          <div className="inputContainer">
            <label htmlFor="password">Password</label>
            <input
              name="password"
              type="password"
              placeholder="Enter your password"
              ref={userPassword}
            />
          </div>
          <Button type="submit" label={loader ? "Logging in..." : "Log in"} />
          {errorMsg ? <p style={{color: 'red'}}>{errorMsg}</p> : null}
        </form>
        <p></p>
      </section>
    </div>
  );
};

export default Login;
