import React, { useState, useEffect } from "react";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import { GET_API_URLS } from "../../../../utils/constant";
import styles from "./style.module.scss";
import {
  PinMap,
  PeopleFill,
  FilePost,
  TagsFill,
  ImageFill,
} from "react-bootstrap-icons";
import { Loader, ModuleCard } from "../../../../components/ui";
import { useAuth } from "../../../../context/AuthProvider";

const CommonDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modulesData, setModuleData] = useState(null);
  const { userData } = useAuth();

  useEffect(() => {
    if (userData.modules.length > 0) {
      jwtInterceoptor
        .get(GET_API_URLS.MODULE)
        .then((response) => {
          console.log(response.data);
          setLoading(false);
          comparingModuleHandler(response.data, userData.modules);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
          console.log("Error", error);
        });
    }
  }, [userData]);

  function comparingModuleHandler(moduleData, userData) {
    let ids = userData.map((val) => val.id);
    const allowedModules = moduleData.filter(
      ({ id }) => ids.includes(id) && id !== 8
    );
    setModuleData(allowedModules);
  }

  // console.log("module data 34", { modulesData, userData });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div>
        <h2 style={{ textAlign: "center" }}>
          There was an error file fetching your permissions.
        </h2>
      </div>
    );
  }

  const moduleIconHandler = (moduleName) => {
    if (moduleName === 1) {
      return (
        <>
          <PinMap size={45} color="var(--secondaryColor)" />
        </>
      );
    } else if (moduleName === 2) {
      return (
        <>
          <PeopleFill size={45} color="var(--secondaryColor)" />
        </>
      );
    } else if (moduleName === 4) {
      return (
        <>
          <FilePost size={45} color="var(--secondaryColor)" />
        </>
      );
    } else if (moduleName === 3 || moduleName === 5) {
      return (
        <>
          <TagsFill size={45} color="var(--secondaryColor)" />
        </>
      );
    } else if (moduleName === 7) {
      return (
        <>
          <ImageFill size={45} color="var(--secondaryColor)" />
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.commonDashboardPage}>
      {modulesData && modulesData.length > 0 ? (
        modulesData.map((val, i) => (
          <ModuleCard
            icon={moduleIconHandler(val.id)}
            title={val.name}
            id={val.id}
            key={i}
          />
        ))
      ) : (
        <h2>Oops! You dont have permission to access any modules.</h2>
      )}
    </div>
  );
};

export default CommonDashboard;
