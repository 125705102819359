import React from "react";
import { Formik, Form } from "formik";
import { Button, FormikInput, UploadFile } from "../../../ui";
import { addBlogInitialValues } from "../../../../utils/formik/initialValues";
import { addBlogValidationSchema } from "../../../../utils/formik/validationSchema";
import TextEditor from "../../textEditor";
import styles from "../addNewLocationForm/style.module.scss";

const AddNewBlogForm = (props) => {
  return (
    <Formik
      initialValues={props.formInitialValues || addBlogInitialValues}
      validationSchema={addBlogValidationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        if (props.formInitialValues) {
          const updatedDataObj = {};
          const keys = Object.keys(props.formInitialValues);
          keys.map((key) => {
            if (props.formInitialValues[key] !== values[key]) {
              return (updatedDataObj[key] = values[key]);
            }
            return false;
          });
          props.updateData({ ...updatedDataObj, id: values.id });
        } else {
          props.data(values);
        }
      }}
    >
      {({ errors, touched, getFieldProps, setFieldValue, values }) => (
        <Form className={styles.addLocationForm}>
          <section>
            <div>
              <FormikInput
                type="text"
                name="title"
                label="Title"
                formikProps={getFieldProps("title")}
                formikTouch={touched.title}
                formikErrors={errors.title}
                style={
                  touched.title && errors.title ? { borderColor: "red" } : null
                }
              />
            </div>
            <div>
              {/* <Textarea
                name="content"
                label="Content"
                formikProps={getFieldProps("content")}
                formikTouch={touched.content}
                formikErrors={errors.content}
                style={
                  touched.content && errors.content
                    ? { borderColor: "red" }
                    : null
                }
              /> */}
              <TextEditor
                setFieldValue={(val) => setFieldValue("content", val)}
                value={values.content}
              />
            </div>
            <div style={{marginTop: "3rem"}}>
              <UploadFile
                label="Image"
                name="image"
                onChange={(e) =>
                  setFieldValue("image", e.currentTarget.files[0])
                }
                formikTouch={touched.image}
                formikErrors={errors.image}
                style={
                  touched.image && errors.image ? { borderColor: "red" } : null
                }
              />
            </div>
            <div>
              <Button
                type="submit"
                label={props.loading ? "Saving..." : "Save"}
                disabled={props.loading}
                primary
                style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
              />
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewBlogForm;
