import React from "react";
import { Outlet, useLocation } from "react-router-dom";
/* Importing stylesheet */
import styles from "./style.module.scss";
import { DashboardHeader, Sidebar } from "../../components/parts";

const Dashboard = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const pageTitle =
    pathname === "/dashboard"
      ? "Welcome to Canco Admin Panel"
      : pathname === "/dashboard/locations"
      ? "Locations"
      : pathname === "/dashboard/subAdmin"
      ? "Sub Admin"
      : pathname === "/dashboard/webPromotions"
      ? "Promotions"
      : pathname === "/dashboard/banner"
      ? "Banners"
      : pathname === "/dashboard/communityCare"
      ? "Community Care"
      : pathname === "/dashboard/addNewLocation"
      ? !location.state
        ? "Add New Location"
        : "Edit Location"
      : pathname === "/dashboard/addBanner"
      ? !location.state
        ? "Add New Banner"
        : "Edit Banner"
      : pathname === "/dashboard/addSubAdmin"
      ? !location.state
        ? "Add Sub Admin"
        : "Edit Sub Admin"
      : pathname === "/dashboard/addPromotion"
      ? !location.state
        ? "Add Promotion"
        : "Edit Promotion"
      : pathname === "/dashboard/addWebPromotion"
      ? !location.state
        ? "Add Promotion"
        : "Edit Promotion"
      : pathname === "/dashboard/addBlog"
      ? !location.state
        ? "Add Post"
        : "Edit Post"
      : null;
  return (
    <div className={styles.dashboardContainer}>
      <Sidebar />
      <div className={styles.dashboardContentContainer}>
        <div id="popupBlock"></div>
        <DashboardHeader pageTitle={pageTitle} pathName={pathname} />
        <div className={styles.dashboardContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
