import React from "react";
/* Importing routes and authProvider */
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { RequireAuth } from "./utils/RequireAuth";
/* Importing Components and pages */
import { Dashboard, Login, PageNotFound } from "./screens";
import {
  Locations,
  SubAdmin,
  Promotions,
  CommunityCare,
  AddNewLocation,
  AddSubAdmin,
  AddPromotion,
  AddBlog,
  WebPromotions,
  AddWebPromotion,
  CommonDashboard,
  Banner,
  AddBanner,
} from "./screens/dashboard/dashboardScreens";
/* Importing stylesheets */
import "./App.scss";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          {/* Dashboard routes descendants */}
          <Route index element={<CommonDashboard />} />
          <Route path="locations" element={<Locations />} />
          <Route path="subAdmin" element={<SubAdmin />} />
          <Route path="promotions" element={<Promotions />} />
          <Route path="webPromotions" element={<WebPromotions />} />
          <Route path="banner" element={<Banner />} />
          <Route path="communityCare" element={<CommunityCare />} />
          <Route path="addNewLocation" element={<AddNewLocation />} />
          <Route path="addSubAdmin" element={<AddSubAdmin />} />
          <Route path="addPromotion" element={<AddPromotion />} />
          <Route path="addWebPromotion" element={<AddWebPromotion />} />
          <Route path="addBlog" element={<AddBlog />} />
          <Route path="addBanner" element={<AddBanner />} />
        </Route>
        {/* If page is not found! */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
