import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";

const ModuleCard = (props) => {
  const navigate = useNavigate();

  const moduleNavigateHandler = () => {
    let navLink =
      props.id === 1
        ? "locations"
        : props.id === 2
        ? "subAdmin"
        : props.id === 3
        ? "promotions"
        : props.id === 4
        ? "communityCare"
        : props.id === 5
        ? "webPromotions"
        : props.id === 7
        ? "banner"
        : null;
    navigate(navLink);
  };

  return (
    <div className={styles.moduleCard} onClick={moduleNavigateHandler}>
      <span className={styles.topKey}></span>
      <div className={styles.moduleContent}>
        {props.icon && props.icon}
        <h2>{props.title}</h2>
      </div>
      <span className={styles.bottomKey1}></span>
      <span className={styles.bottomKey2}></span>
    </div>
  );
};

export default ModuleCard;
