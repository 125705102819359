import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import { useAuth } from "../../../../context/AuthProvider";
import styles from "./style.module.scss";
import AddNewLocationForm from "../../../../components/parts/forms/addNewLocationForm";
import { POST_API_URLS, TIMINGS } from "../../../../utils/constant";
import { CardWrapper } from "../../../../components/wrapper";
import { FormErrorPopup } from "../../../../components/parts/popups";

const AddNewLocation = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useAuth();

  // console.log(location.state);

  /* Adding New Location API Call */

  const submitDataHandler = (storeData) => {
    setLoading(true);

    let dataObj = {};

    for (let k in storeData) {
      if (k === "business_type" || k === "city" || k === "province") {
        dataObj[k] = parseInt(storeData[k]);
      } else if (k === "amenities") {
        dataObj[k] = storeData[k].map((val) => parseInt(val));
      } else if (storeData[k] === "") {
        dataObj[k] = null;
      } else if (k === "timings" && storeData[k].length > 0) {
        dataObj[k] = storeData[k].map((val, i) => {
          if (!val.day[0]) {
            return {
              day: i + 1,
              open_time: "00:00:00",
              close_time: "00:00:00",
            };
          } else {
            return {
              day: parseInt(val.day[0]),
              open_time: val.open_time,
              close_time: val.close_time,
            };
          }
        });
      } else {
        dataObj[k] = storeData[k];
      }
    }

    console.log("Store Data", dataObj);

    jwtInterceoptor
      .post(POST_API_URLS.ADD_NEW_LOCATION_URL, dataObj)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        navigate("/dashboard/locations");
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
        console.log("Error", error);
      });
  };

  /* Updating Location API Call */

  const updateDataHandler = (data) => {
    console.log("update Location", data);

    setLoading(true);

    let dataObj = {};

    for (let k in data) {
      if (k === "business_type" || k === "city" || k === "province") {
        dataObj[k] = parseInt(data[k]);
      } else if (k === "amenities") {
        dataObj[k] = data[k].map((val) => parseInt(val));
      } else if (data[k] === "") {
        dataObj[k] = null;
      } else if (k === "timings" && data[k].length > 0) {
        dataObj[k] = data[k].map((val, i) => {
          if (!val.day[0]) {
            return {
              day: i + 1,
              open_time: "00:00:00",
              close_time: "00:00:00",
            };
          } else {
            return {
              day: parseInt(val.day[0]),
              open_time: val.open_time,
              close_time: val.close_time,
            };
          }
        });
      } else {
        dataObj[k] = data[k];
      }
    }

    // console.log("update Location", data);

    jwtInterceoptor
      .patch(POST_API_URLS.ADD_NEW_LOCATION_URL + `${data.id}/`, dataObj)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        navigate("/dashboard/locations");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403) {
          setError("You dont have permission to add location.");
        } else {
          setError(error.message);
        }
        console.log("Error", error);
      });
  };

  return (
    <>
      <div className={styles.addLocationContainer}>
        <CardWrapper>
          <AddNewLocationForm
            storeData={submitDataHandler}
            updateStoreData={updateDataHandler}
            cityData={auth.cities}
            provinceData={auth.province}
            formInitialValues={
              location.state
                ? location.state.is24by7 === true
                  ? { ...location.state, timings: TIMINGS }
                  : { ...location.state }
                : null
            }
            loading={loading}
          />
        </CardWrapper>
      </div>
      {error && <FormErrorPopup error={error} close={() => setError(false)} />}
    </>
  );
};

export default AddNewLocation;
