import React from "react";

const FormikInput = (props) => {
    // console.log('formik props', props.formikProps);
  return (
    <div className="inputContainer">
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <input
        type={props.type}
        // name={props.name}
        // id={props.name}
        placeholder={props.placeholder}
        style={props.style}
        {...props.formikProps}
        disabled={props.disabled || false}
      />
      {props.formikTouch && props.formikErrors ? (
        <span className="errorText">{props.formikErrors}</span>
      ) : null}
    </div>
  );
};

export default FormikInput;
