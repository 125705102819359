import React from "react";
import markerImg from "../../../assets/marker.png";

const Marker = (props) => {
  // console.log("markers", props.data);
  return (
    <>
      <div
        className="mapMarker"
        onClick={() =>
          props.onMarkerClick(
            props.data.id,
            props.data.name,
            props.data.address,
            props.data.city,
            props.data.province
          )
        }
      >
        <img src={markerImg} alt="Map Marker" />
      </div>
      {props.isOpen && props.infoWindowData?.id === props.data.id && (
        <div className="mapInfoWindow">
          <div>
            <h5>Name:</h5>
            <p>{props.infoWindowData.name}</p>
          </div>
          <div>
            <h5>Address:</h5>
            <p>
              {props.infoWindowData.address}
              <br />
              {props.infoWindowData.city.name},{" "}
              {props.infoWindowData.province.name}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Marker;
