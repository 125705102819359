import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import { AddWebPromotionForm } from "../../../../components/parts/forms";
import { POST_API_URLS, GET_API_URLS } from "../../../../utils/constant";
import styles from "./style.module.scss";
import { CardWrapper } from "../../../../components/wrapper";
import { FormErrorPopup } from "../../../../components/parts/popups";
import { Loader } from "../../../../components/ui";

const AddWebPromotion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [nextSequence, setNextSequence] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [promotionList, setPromotionList] = useState(null);
  const [categoryList, setCategoryList] = useState(null);

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    // Getting Promotions List
    jwtInterceoptor
      .get(GET_API_URLS.WEB_PROMOTIONS)
      .then((response) => {
        // console.log(response);
        setFormLoading(false);
        setPromotionList(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });

    // Getting Promotions Categories List
    jwtInterceoptor
      .get(GET_API_URLS.WEB_PROMOTIONS_CATEGORY)
      .then((response) => {
        // console.log(response);
        setFormLoading(false);
        setCategoryList(response.data);
      })
      .catch((error) => {
        setFormLoading(false);
        console.log("Error", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryData = promotionList.filter(
        (val) => val.category.id === parseInt(selectedCategory)
      );
      setNextSequence(selectedCategoryData.length + 1);
    }
  }, [selectedCategory, promotionList]);

  const addWebPromotionHandler = (data) => {
    setLoading(true);
    let formData = new FormData();
    for (let k in data) {
      formData.append(k, data[k]);
    }

    jwtInterceoptor
      .post(POST_API_URLS.ADD_WEB_PROMOTION, formData)
      .then((response) => {
        console.log(response.data);
        navigate("/dashboard/webPromotions");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 400) {
          setError(error.response.data.detail);
        } else {
          setError(error.message);
        }
        console.log("Error", error);
      });
  };

  const updateWebPromotionHandler = (data) => {
    setLoading(true);
    let formData = new FormData();
    for (let k in data) {
      formData.append(k, data[k]);
    }

    jwtInterceoptor
      .patch(POST_API_URLS.ADD_WEB_PROMOTION + `${data.id}/`, formData)
      .then((response) => {
        console.log(response.data);
        navigate("/dashboard/webPromotions");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403) {
          setError("You dont have permission to add promotions.");
        } else {
          setError(error.message);
        }
        console.log("Error", error);
      });
  };

  console.log("promotion", promotionList);

  return (
    <>
      <div className={styles.addPromotionContainer}>
        <CardWrapper>
          {formLoading ? (
            <Loader />
          ) : (
            <AddWebPromotionForm
              updateData={updateWebPromotionHandler}
              data={addWebPromotionHandler}
              loading={loading}
              formInitialValues={location.state || null}
              categoryData={categoryList}
              nextSequence={nextSequence}
              selectedCategory={(val) => setSelectedCategory(val)}
            />
          )}
        </CardWrapper>
      </div>
      {error && <FormErrorPopup error={error} close={() => setError(false)} />}
    </>
  );
};

export default AddWebPromotion;
