import { useState } from "react";

const UploadFile = (props) => {
  const [uploadName, setUploadName] = useState(props.label || 'Upload Image');

  return (
    <div className="uploadLogoContainer">
      <div className="uploadContainer">
        <input
          type="file"
          id={props.name}
          name={props.name}
          hidden
          onChange={(e) => {
            props.onChange(e);
            setUploadName(e.target.value);
          }}
          // {...props.formikProps}
          // onBlur={props.onBlur}
        />

        <label htmlFor={props.name}>UPLOAD</label>
        <span id={`fileChosen choosenFile`}>{uploadName}</span>
      </div>
      {props.formikTouch && props.formikErrors ? (
        <span className="errorText">{props.formikErrors}</span>
      ) : null}
    </div>
  );
};

export default UploadFile;
