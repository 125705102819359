import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtInterceoptor from "../../../api/jwtInterceptor";
import { GET_API_URLS } from "../../../utils/constant";
import { FormErrorPopup } from "../../parts/popups";
import Button from "../button";
import styles from "./style.module.scss";
import Ribbon from "../ribbon";

const PromotionCard = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const editWebPromotionHandler = (data) => {
    navigate("/dashboard/addWebPromotion", { state: data });
  };

  const deleteWebPromotionHandler = (id) => {
    setIsDeleting(true);
    jwtInterceoptor
      .delete(GET_API_URLS.WEB_PROMOTIONS + `${id}/`)
      .then((response) => {
        setIsDeleting(false);
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        setIsDeleting(false);
        setError(error.message);
        console.log("Error", error);
      });
  };

  return (
    <>
      <div className={styles.promotionCard}>
        <div className={styles.promotionImage}>
          <img src={props.image} alt="Promotion" />
          {props.data.featured_banner && <Ribbon title="Featured" />}
        </div>
        <div className={styles.promotionContent}>
          <h2>{props.title}</h2>
          <div className={styles.promotionInfo}>
            <div className={styles.promotionDate}>
              <p>
                <b>Start Date:</b>
                <br />
                {props.startDate}
              </p>
              <p>
                <b>End Date:</b>
                <br />
                {props.endDate}
              </p>
            </div>
            <div className={styles.sequence}>
              <span>{props.sequenceNumber}</span>
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button
            label="Edit"
            secondary
            onClick={() => editWebPromotionHandler(props.data)}
          />
          <Button
            label={isDeleting ? "Deleting..." : "Delete"}
            primary
            disabled={isDeleting}
            onClick={() => {
              deleteWebPromotionHandler(props.id);
            }}
          />
        </div>
      </div>
      {error ? (
        <FormErrorPopup
          error={error}
          close={() => setError(false)}
          errorText="Oops! An error occured"
        />
      ) : null}
    </>
  );
};

export default PromotionCard;
