import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import jwtInterceoptor from "../api/jwtInterceptor";
import { GET_API_URLS } from "../utils/constant";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    stores: null,
    sub_admin: null,
    promotions: null,
    webPromotions: null,
    webPromotionsCategory: null,
    blogs: null,
    cities: null,
    province: null,
    banner: null,
  });

  const [userData, setUserData] = useState({
    firstName: "User",
    lastName: "",
    modules: [],
  });

  // console.log("fetched data -->", auth);

  const [user, setUser] = useState(() => {
    if (localStorage.getItem("tokens")) {
      let tokens = JSON.parse(localStorage.getItem("tokens"));
      return jwtDecode(tokens.access);
    }
    return null;
  });

  // Fetching user data

  useEffect(() => {
    if (user) {
      jwtInterceoptor
        .get(GET_API_URLS.SUB_ADMIN_URL + `${user.user_id}/`)
        .then((response) => {
          // console.log("logged in user", response.data);
          setUserData({
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            modules: response.data.modules,
          });
        })
        .catch(() => {
          setUserData({
            firstName: "",
            lastName: "",
            modules: [],
          });
        });
    }
  }, [user]);

  const navigate = useNavigate();

  const login = async (payload) => {
    try {
      const apiResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/accounts/login/`,
        payload
      );
      localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
      setUser(jwtDecode(apiResponse.data.access));
      navigate("/dashboard");
      return { msg: false };
    } catch (error) {
      let errorMsg;
      if (!error?.response) {
        errorMsg = "No Server Response";
      } else if (error.response?.status === 400) {
        errorMsg = "Missing username or password";
      } else if (error.response?.status === 401) {
        errorMsg = "Unauthorized Access";
      } else {
        errorMsg = "Login Failed!";
      }
      return { msg: errorMsg };
    }
  };

  // console.log("user data", auth);

  return (
    <AuthContext.Provider value={{ user, login, auth, setAuth, userData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
