import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";

const SearchLocationBar = ({ locations, filteredData }) => {
  const [searchItem, setSearchItem] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(locations);

  console.log("search loca", locations);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems =
      searchItem === ""
        ? locations
        : locations.filter(
            (store) =>
              store.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              store.address.toLowerCase().includes(searchTerm.toLowerCase())
          );

    setFilteredUsers(filteredItems);
  };

  useEffect(() => {
    if (filteredUsers) {
      filteredData(filteredUsers);
    }
  }, [filteredUsers, filteredData]);

  return (
    <input
      type="search"
      value={searchItem}
      className={styles.searchLocationBar}
      onChange={handleInputChange}
      placeholder="Search by location name or address"
    />
  );
};

export default SearchLocationBar;
