import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Button, FormikInput, Select } from "../../../ui";
import { addBannerInitialValues } from "../../../../utils/formik/initialValues";
import {
  addBannerValidationSchema,
  editBannerValidationSchema,
} from "../../../../utils/formik/validationSchema";
import styles from "../addNewLocationForm/style.module.scss";

const AddBannerForm = (props) => {
  //   const categories = props.categoryData?.map((val) => ({
  //     value: val.id,
  //     label: val.name,
  //   }));
  const [uploadName, setUploadName] = useState("Upload File");
  console.log("edit", props.formInitialValues);
  return (
    <Formik
      initialValues={
        props.formInitialValues
          ? { ...props.formInitialValues, banner: "" }
          : {
              ...addBannerInitialValues,
            }
      }
      validationSchema={
        props.formInitialValues
          ? editBannerValidationSchema
          : addBannerValidationSchema
      }
      enableReinitialize={true}
      onSubmit={(values) => {
        if (props.formInitialValues) {
          const updatedDataObj = {};
          const keys = Object.keys(props.formInitialValues);
          keys.map((key) => {
            if (props.formInitialValues[key] !== values[key]) {
              return (updatedDataObj[key] = values[key]);
            }
            return false;
          });
          props.updateData({
            ...updatedDataObj,
            id: values.id,
          });
        } else {
          props.data({
            ...values,
            sequence_number: props.nextSequence,
          });
        }
      }}
    >
      {({
        errors,
        touched,
        getFieldProps,
        setFieldValue,
        handleBlur,
        isValid,
        dirty,
      }) => (
        <Form className={styles.addLocationForm}>
          <section>
            {props.formInitialValues ? (
              <div className={styles.bannerImgInput}>
                <div className="uploadLogoContainer uploadVendorFile">
                  <div className="uploadContainer">
                    <input
                      type="file"
                      name="banner"
                      // hidden
                      onChange={(e) => {
                        setUploadName(e.target.files[0].name);
                        setFieldValue("banner", e.currentTarget.files[0]);
                      }}
                      onBlur={handleBlur("banner")}
                    />

                    <label htmlFor="actual-btn">UPLOAD</label>
                    <span id={`fileChosen choosenFile`}>{uploadName}</span>
                  </div>
                </div>
                {touched.banner && errors.banner && (
                  <span className={"errorText"}>{errors.banner}</span>
                )}
                {/* <UploadFile
                  label="Banner Image*"
                  name="banner"
                  onChange={(e) =>
                    setFieldValue("banner", e.currentTarget.files[0])
                  }
                  formikTouch={touched.banner}
                  formikErrors={errors.banner}
                  style={
                    touched.banner && errors.banner
                      ? { borderColor: "red" }
                      : null
                  }
                />
                {touched.banner && errors.banner ? (
                  <span className="errorText">{errors.banner}</span>
                ) : null} */}
              </div>
            ) : (
              <div>
                <div className="selectContainer">
                  <label htmlFor="module">Category*</label>
                  <select
                    onChange={(e) => {
                      props.selectedCategory(e.target.value);
                      setFieldValue("module", e.target.value);
                    }}
                    onBlur={handleBlur("module")}
                    className={styles.categoryListSelector}
                    style={
                      touched.module && errors.module
                        ? { borderColor: "red" }
                        : null
                    }
                    defaultValue={""}
                  >
                    <option value={""} disabled={true}>
                      Select
                    </option>
                    <option value={"DASHBOARD"}>Home</option>
                    <option value={"STORE"}>In-Store</option>
                    {/* {categories &&
                      categories.map((option, i) => (
                        <option value={option.value} key={i}>
                          {option.label}
                        </option>
                      ))} */}
                  </select>
                  {touched.module && errors.module ? (
                    <span className="errorText">{errors.module}</span>
                  ) : null}
                </div>
              </div>
            )}
            {props.formInitialValues && (
              <div>
                <FormikInput
                  type="number"
                  name="sequence"
                  label="Sequence Number*"
                  formikProps={getFieldProps("sequence")}
                  formikTouch={touched.sequence}
                  formikErrors={errors.sequence}
                  style={
                    touched.sequence && errors.sequence
                      ? { borderColor: "red" }
                      : null
                  }
                />
              </div>
            )}
            {props.formInitialValues ? (
              <div>
                <Select
                  name="module"
                  label="Category*"
                  formikProps={getFieldProps("module")}
                  formikTouch={touched.module}
                  formikErrors={errors.module}
                  options={[
                    {
                      value: "DASHBOARD",
                      label: "Home",
                    },
                    {
                      value: "STORE",
                      label: "In-Store",
                    },
                  ]}
                  style={
                    touched.module && errors.module
                      ? { borderColor: "red" }
                      : null
                  }
                  disabled
                />
              </div>
            ) : (
              <div className={styles.bannerImgInput}>
                <div className="uploadLogoContainer uploadVendorFile">
                  <div className="uploadContainer">
                    <input
                      type="file"
                      name="banner"
                      // hidden
                      onChange={(e) => {
                        setUploadName(e.target.files[0].name);
                        setFieldValue("banner", e.currentTarget.files[0]);
                      }}
                      onBlur={handleBlur("banner")}
                    />

                    <label htmlFor="actual-btn">UPLOAD</label>
                    <span id={`fileChosen choosenFile`}>{uploadName}</span>
                  </div>
                </div>
                {touched.banner && errors.banner && (
                  <span className={"errorText"}>{errors.banner}</span>
                )}
                {/* <UploadFile
                  label="Banner Image*"
                  name="banner"
                  onChange={(e) =>
                    setFieldValue("banner", e.currentTarget.files[0])
                  }
                  formikTouch={touched.banner}
                  formikErrors={errors.banner}
                  style={
                    touched.banner && errors.banner
                      ? { borderColor: "red" }
                      : null
                  }
                />
                {touched.banner && errors.banner ? (
                  <span className="errorText">{errors.banner}</span>
                ) : null} */}
              </div>
            )}
            <div>
              <label
                className="material-checkbox"
                style={{ width: "100%", fontSize: "18px" }}
              >
                <Field type="checkbox" name="featured_banner" />
                <span className="checkmark"></span>
                Featured Banner
              </label>
            </div>
            <div>
              <Button
                type="submit"
                label={props.loading ? "Saving..." : "Save"}
                disabled={props.loading || !(isValid && dirty)}
                primary
                style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
              />
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default AddBannerForm;
