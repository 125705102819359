import React, { useState, useEffect } from "react";
import { GET_API_URLS } from "../../../../utils/constant";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import { Loader, PromotionCard } from "../../../../components/ui";
import { useAuth } from "../../../../context/AuthProvider";
import styles from "./style.module.scss";

const WebPromotions = () => {
  const [activeCategory, setActiveCategory] = useState(3);
  const [loading, setLoading] = useState(true);
  const [promotionList, setPromotionList] = useState(null);
  const [chillerzList, setChillerzList] = useState(null);
  const [foodToGoList, setFoodToGoList] = useState(null);
  const [error, setError] = useState(false);
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    // Getting Promotions List
    jwtInterceoptor
      .get(GET_API_URLS.WEB_PROMOTIONS)
      .then((response) => {
        // console.log(response);
        // setLoading(false);
        setAuth((prev) => ({ ...prev, webPromotions: response.data }));
      })
      .catch((error) => {
        // setLoading(false);
        // setError(error.message);
        console.log("Error", error);
      });

    // Getting Promotions Categories List
    jwtInterceoptor
      .get(GET_API_URLS.WEB_PROMOTIONS_CATEGORY)
      .then((response) => {
        // console.log(response);
        setLoading(false);
        setAuth((prev) => ({ ...prev, webPromotionsCategory: response.data }));
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403) {
          setError("You dont have permission to access this page.");
        } else {
          setError("There was a problem while loading the data.");
        }
        console.log("Error", error);
      });
  }, [setAuth]);

  // Dividing promotion data into categories
  useEffect(() => {
    if (auth.webPromotions) {
      setPromotionList(
        auth.webPromotions
          ?.sort((a, b) => a.sequence_number - b.sequence_number)
          .filter((val) => val.category.id === 3)
      );
      setChillerzList(
        auth.webPromotions
          ?.sort((a, b) => a.sequence_number - b.sequence_number)
          .filter((val) => val.category.id === 2)
      );
      setFoodToGoList(
        auth.webPromotions
          ?.sort((a, b) => a.sequence_number - b.sequence_number)
          .filter((val) => val.category.id === 1)
      );
    }
  }, [auth]);

  console.log("Promotion List", { promotionList, chillerzList, foodToGoList });

  if (loading) {
    return (
      <div className={styles.loadingPage}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.webPromotionPage}>
      {error ? (
        <h3 style={{ textAlign: "center" }}>{error}</h3>
      ) : (
        <>
          <div className={styles.webPromotionCategory}>
            {auth &&
              auth.webPromotionsCategory &&
              auth.webPromotionsCategory.map((val, i) => (
                <div key={i}>
                  <button
                    className={
                      activeCategory === val.id
                        ? styles.activeButton
                        : styles.categoryBtn
                    }
                    onClick={() => setActiveCategory(val.id)}
                  >
                    <span>{val.name}</span>
                  </button>
                </div>
              ))}
          </div>

          {activeCategory === 3 && (
            <div className={styles.promotionBlock}>
              {promotionList?.length > 0 ? (
                promotionList.map((val, i) => (
                  <PromotionCard
                    id={val.id}
                    image={val.image}
                    title={val.name}
                    startDate={val.effective_date}
                    endDate={val.expiration_date}
                    data={{ ...val, category: val.category.id }}
                    key={i}
                    sequenceNumber={val.sequence_number}
                  />
                ))
              ) : (
                <div>
                  <h2 style={{ textAlign: "center" }}>No Web Promotions</h2>
                </div>
              )}
            </div>
          )}
          {activeCategory === 2 && (
            <div className={styles.promotionBlock}>
              {chillerzList?.length > 0 ? (
                chillerzList.map((val, i) => (
                  <PromotionCard
                    id={val.id}
                    image={val.image}
                    title={val.name}
                    startDate={val.effective_date}
                    endDate={val.expiration_date}
                    data={{ ...val, category: val.category.id }}
                    key={i}
                    sequenceNumber={val.sequence_number}
                  />
                ))
              ) : (
                <div>
                  <h2 style={{ textAlign: "center" }}>No Web Promotions</h2>
                </div>
              )}
            </div>
          )}
          {activeCategory === 1 && (
            <div className={styles.promotionBlock}>
              {foodToGoList?.length > 0 ? (
                foodToGoList.map((val, i) => (
                  <PromotionCard
                    id={val.id}
                    image={val.image}
                    title={val.name}
                    startDate={val.effective_date}
                    endDate={val.expiration_date}
                    data={{ ...val, category: val.category.id }}
                    key={i}
                    sequenceNumber={val.sequence_number}
                  />
                ))
              ) : (
                <div>
                  <h2 style={{ textAlign: "center" }}>No Web Promotions</h2>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WebPromotions;
