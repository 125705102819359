import React from "react";
import { Formik, Form, Field } from "formik";
import { Button, FormikInput, Select } from "../../../ui";
import { addNewLocationInitialValues } from "../../../../utils/formik/initialValues";
import { addNewLocationValidationSchema } from "../../../../utils/formik/validationSchema";
import styles from "./style.module.scss";

const AddNewLocationForm = (props) => {
  const business_type = [
    {
      value: "1",
      label: "Gas Station",
    },
    {
      value: "2",
      label: "Super Market",
    },
    {
      value: "3",
      label: "One Stop",
    },
  ];

  const cities = props.cityData?.map((val) => ({
    value: val.id,
    label: val.name,
  }));

  const province = props.provinceData?.map((val) => ({
    value: val.id,
    label: val.name,
  }));

  // console.log("initailVal", props.formInitialValues);
  return (
    <Formik
      initialValues={props.formInitialValues || addNewLocationInitialValues}
      validationSchema={addNewLocationValidationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        if (values.is24by7 === true) {
          delete values.timings;
        }
        if (props.formInitialValues) {
          const updatedDataObj = {};
          const keys = Object.keys(props.formInitialValues);
          keys.map((key) => {
            if (props.formInitialValues[key] !== values[key]) {
              if (!(key === "timings" && !values[key] && values.is24by7)) {
                return (updatedDataObj[key] = values[key]);
              }
            }
            return false;
          });

          console.log("updated data", updatedDataObj);
          props.updateStoreData({ ...updatedDataObj, id: values.id });
        } else {
          props.storeData(values);
        }
      }}
    >
      {({ errors, touched, setFieldValue, getFieldProps, values }) => (
        <Form className={styles.addLocationForm}>
          <section>
            <div>
              <FormikInput
                type="text"
                name="canco_site_id"
                label="Site ID"
                formikProps={getFieldProps("canco_site_id")}
                formikTouch={touched.canco_site_id}
                formikErrors={errors.canco_site_id}
                style={
                  touched.canco_site_id && errors.canco_site_id
                    ? { borderColor: "red" }
                    : null
                }
              />
              <FormikInput
                type="text"
                name="ackroo_canco_location"
                label="Ackroo Canco Location"
                formikProps={getFieldProps("ackroo_canco_location")}
                formikTouch={touched.ackroo_canco_location}
                formikErrors={errors.ackroo_canco_location}
                style={
                  touched.ackroo_canco_location && errors.ackroo_canco_location
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            <div>
              <FormikInput
                type="text"
                name="name"
                label="Name"
                formikProps={getFieldProps("name")}
                formikTouch={touched.name}
                formikErrors={errors.name}
                style={
                  touched.name && errors.name ? { borderColor: "red" } : null
                }
              />
              <Select
                name="business_type"
                label="Business Type"
                formikProps={getFieldProps("business_type")}
                formikTouch={touched.business_type}
                formikErrors={errors.business_type}
                options={business_type}
                style={
                  touched.business_type && errors.business_type
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            <div>
              <FormikInput
                type="text"
                name="address"
                label="Address"
                formikProps={getFieldProps("address")}
                formikTouch={touched.address}
                formikErrors={errors.address}
                style={
                  touched.address && errors.address
                    ? { borderColor: "red" }
                    : null
                }
              />
              <Select
                name="city"
                label="City"
                formikProps={getFieldProps("city")}
                formikTouch={touched.city}
                formikErrors={errors.city}
                options={cities}
                style={
                  touched.city && errors.city ? { borderColor: "red" } : null
                }
              />
            </div>
            <div>
              <Select
                name="province"
                label="Province"
                formikProps={getFieldProps("province")}
                formikTouch={touched.province}
                formikErrors={errors.province}
                options={province}
                style={
                  touched.province && errors.province
                    ? { borderColor: "red" }
                    : null
                }
              />
              <FormikInput
                type="text"
                name="postal_code"
                label="Postal"
                formikProps={getFieldProps("postal_code")}
                formikTouch={touched.postal}
                formikErrors={errors.postal}
                style={
                  touched.postal_code && errors.postal_code
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            <div>
              <FormikInput
                type="text"
                name="latitude"
                label="Latitude"
                formikProps={getFieldProps("latitude")}
                formikTouch={touched.latitude}
                formikErrors={errors.latitude}
                style={
                  touched.latitude && errors.latitude
                    ? { borderColor: "red" }
                    : null
                }
              />
              <FormikInput
                type="text"
                name="longitude"
                label="Longitude"
                formikProps={getFieldProps("longitude")}
                formikTouch={touched.longitude}
                formikErrors={errors.longitude}
                style={
                  touched.longitude && errors.longitude
                    ? { borderColor: "red" }
                    : null
                }
              />
            </div>
            {/* <div>
              <UploadFile
                onChange={(e) => setFieldValue("image", e.target.value)}
              />
            </div> */}
          </section>
          <section>
            <div className={styles.amenitiesContainer}>
              <h3 id="checkbox-group">Amenities</h3>
              <div role="group" aria-labelledby="checkbox-group">
                <label className="material-checkbox">
                  <Field type="checkbox" name="amenities" value={"1"} />
                  <span className="checkmark"></span>
                  Convenience Store
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="amenities" value={"2"} />
                  <span className="checkmark"></span>
                  Regular Gas
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="amenities" value={"3"} />
                  <span className="checkmark"></span>
                  Premium Gas
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="amenities" value={"4"} />
                  <span className="checkmark"></span>
                  Diesel
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="amenities" value={"5"} />
                  <span className="checkmark"></span>
                  Propane
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="amenities" value={"6"} />
                  <span className="checkmark"></span>
                  Car Wash
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="amenities" value={"7"} />
                  <span className="checkmark"></span>
                  ATM
                </label>
                <label className="material-checkbox">
                  <Field type="checkbox" name="amenities" value={"8"} />
                  <span className="checkmark"></span>
                  Liquor
                </label>
              </div>
            </div>
            <div className={styles.storeHoursContainer}>
              <h3>Store Hours</h3>
              <label className="material-checkbox">
                <Field type="checkbox" name="is24by7" />
                <span className="checkmark"></span>
                Open 24/7
              </label>
              {!values.is24by7 && (
                <div>
                  <div className="storeHoursBlock">
                    <label className="material-checkbox">
                      <Field
                        type="checkbox"
                        name="timings[0].day"
                        value={"1"}
                      />
                      <span className="checkmark"></span>
                      Monday
                    </label>
                    <FormikInput
                      type="time"
                      name="timings[0].open_time"
                      formikProps={getFieldProps("timings[0].open_time")}
                    />
                    <FormikInput
                      type="time"
                      name="timings[0].close_time"
                      formikProps={getFieldProps("timings[0].close_time")}
                    />
                  </div>
                  <div className="storeHoursBlock">
                    <label className="material-checkbox">
                      <Field
                        type="checkbox"
                        name="timings[1].day"
                        value={"2"}
                      />
                      <span className="checkmark"></span>
                      Tuesday
                    </label>
                    <FormikInput
                      type="time"
                      name="timings[1].open_time"
                      formikProps={getFieldProps("timings[1].open_time")}
                    />
                    <FormikInput
                      type="time"
                      name="timings[1].close_time"
                      formikProps={getFieldProps("timings[1].close_time")}
                    />
                  </div>
                  <div className="storeHoursBlock">
                    <label className="material-checkbox">
                      <Field
                        type="checkbox"
                        name="timings[2].day"
                        value={"3"}
                      />
                      <span className="checkmark"></span>
                      Wednesday
                    </label>
                    <FormikInput
                      type="time"
                      name="timings[2].open_time"
                      formikProps={getFieldProps("timings[2].open_time")}
                    />
                    <FormikInput
                      type="time"
                      name="timings[2].close_time"
                      formikProps={getFieldProps("timings[2].close_time")}
                    />
                  </div>
                  <div className="storeHoursBlock">
                    <label className="material-checkbox">
                      <Field
                        type="checkbox"
                        name="timings[3].day"
                        value={"4"}
                      />
                      <span className="checkmark"></span>
                      Thursday
                    </label>
                    <FormikInput
                      type="time"
                      name="timings[3].open_time"
                      formikProps={getFieldProps("timings[3].open_time")}
                    />
                    <FormikInput
                      type="time"
                      name="timings[3].close_time"
                      formikProps={getFieldProps("timings[3].close_time")}
                    />
                  </div>
                  <div className="storeHoursBlock">
                    <label className="material-checkbox">
                      <Field
                        type="checkbox"
                        name="timings[4].day"
                        value={"5"}
                      />
                      <span className="checkmark"></span>
                      Friday
                    </label>
                    <FormikInput
                      type="time"
                      name="timings[4].open_time"
                      formikProps={getFieldProps("timings[4].open_time")}
                    />
                    <FormikInput
                      type="time"
                      name="timings[4].close_time"
                      formikProps={getFieldProps("timings[4].close_time")}
                    />
                  </div>
                  <div className="storeHoursBlock">
                    <label className="material-checkbox">
                      <Field
                        type="checkbox"
                        name="timings[5].day"
                        value={"6"}
                      />
                      <span className="checkmark"></span>
                      Saturday
                    </label>
                    <FormikInput
                      type="time"
                      name="timings[5].open_time"
                      formikProps={getFieldProps("timings[5].open_time")}
                    />
                    <FormikInput
                      type="time"
                      name="timings[5].close_time"
                      formikProps={getFieldProps("timings[5].close_time")}
                    />
                  </div>
                  <div className="storeHoursBlock">
                    <label className="material-checkbox">
                      <Field
                        type="checkbox"
                        name="timings[6].day"
                        value={"7"}
                      />
                      <span className="checkmark"></span>
                      Sunday
                    </label>
                    <FormikInput
                      type="time"
                      name="timings[6].open_time"
                      formikProps={getFieldProps("timings[6].open_time")}
                    />
                    <FormikInput
                      type="time"
                      name="timings[6].close_time"
                      formikProps={getFieldProps("timings[6].close_time")}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <Button
                type="submit"
                label={props.loading ? "Saving..." : "Save"}
                disabled={props.loading}
                primary
                style={{ maxWidth: "20rem", margin: "0 0 0 auto" }}
              />
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewLocationForm;
