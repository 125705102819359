import React, { useState, useEffect } from "react";
import { GET_API_URLS } from "../../../../utils/constant";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import {
  Loader,
  // PromotionCard,
  WebBannerCard,
} from "../../../../components/ui";
import { useAuth } from "../../../../context/AuthProvider";
import styles from "./style.module.scss";

const Banner = () => {
  const [activeCategory, setActiveCategory] = useState(1);
  const [loading, setLoading] = useState(true);
  // const [homeBanner, setHomeBanner] = useState(null);
  // const [inStoreBanner, setInStoreBanner] = useState(null);
  const [bannerList, setBannerList] = useState(null);
  //   const [promotionList, setPromotionList] = useState(null);
  //   const [chillerzList, setChillerzList] = useState(null);
  //   const [foodToGoList, setFoodToGoList] = useState(null);
  const [error, setError] = useState(false);
  const { setAuth } = useAuth();

  useEffect(() => {
    // Getting Promotions List
    jwtInterceoptor
      .get(GET_API_URLS.BANNER + "?module=DASHBOARD")
      .then((response) => {
        // console.log(response);
        setLoading(false);
        // setAuth((prev) => ({ ...prev, banner: response.data }));
        // const sortedSequence = response?.data.filter(
        //   (a, b) => a.sequence - b.sequence
        // );
        setBannerList(response.data);
      })
      .catch((error) => {
            setLoading(false);
        if (error.response.status === 403) {
          setError("You dont have permission to access this page.");
        } else {
          setError("There was a problem while loading the data.");
        }
        console.log("Error", error);
      });

    // Getting Promotions Categories List
    // jwtInterceoptor
    //   .get(GET_API_URLS.WEB_PROMOTIONS_CATEGORY)
    //   .then((response) => {
    //     // console.log(response);
    //     setLoading(false);
    //     setAuth((prev) => ({ ...prev, webPromotionsCategory: response.data }));
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     if (error.response.status === 403) {
    //       setError("You dont have permission to access this page.");
    //     } else {
    //       setError("There was a problem while loading the data.");
    //     }
    //     console.log("Error", error);
    //   });
  }, [setAuth]);

  function changeBannerHandler(module) {
    setLoading(true);
    jwtInterceoptor
      .get(GET_API_URLS.BANNER + `?module=${module}`)
      .then((response) => {
        // console.log(response);
        setLoading(false);
        // setAuth((prev) => ({ ...prev, banner: response.data }));
        setBannerList(response.data);
      })
      .catch((error) => {
        // setLoading(false);
        // setError(error.message);
        console.log("Error", error);
      });
  }

  // Dividing promotion data into categories
  //   useEffect(() => {
  //     if (auth.banner) {
  //       setHomeBanner(
  //         auth.banner
  //           ?.sort((a, b) => a.sequence - b.sequence)
  //           .filter((val) => val.module === "DASHBOARD")
  //       );
  //       setInStoreBanner(
  //         auth.banner
  //           ?.sort((a, b) => a.sequence - b.sequence)
  //           .filter((val) => val.module === "STORE")
  //       );
  //     }
  //   }, [auth]);

  // console.log("Banner List", { homeBanner, inStoreBanner });

  //   if (loading) {
  //     return (
  //       <div className={styles.loadingPage}>
  //         <Loader />
  //       </div>
  //     );
  //   }

  return (
    <div className={styles.webPromotionPage}>
      {error ? (
        <h3 style={{ textAlign: "center" }}>{error}</h3>
      ) : (
        <>
          <div className={styles.webPromotionCategory}>
            {/* {auth &&
              auth.webPromotionsCategory &&
              auth.webPromotionsCategory.map((val, i) => (
                <div key={i}>
                  <button
                    className={
                      activeCategory === val.id
                        ? styles.activeButton
                        : styles.categoryBtn
                    }
                    onClick={() => setActiveCategory(val.id)}
                  >
                    <span>{val.name}</span>
                  </button>
                </div>
              ))} */}
            <div>
              <button
                className={
                  activeCategory === 1
                    ? styles.activeButton
                    : styles.categoryBtn
                }
                onClick={() => {
                  setActiveCategory(1);
                  changeBannerHandler("DASHBOARD");
                }}
              >
                <span>HOME</span>
              </button>
            </div>
            <div>
              <button
                className={
                  activeCategory === 2
                    ? styles.activeButton
                    : styles.categoryBtn
                }
                onClick={() => {
                  setActiveCategory(2);
                  changeBannerHandler("STORE");
                }}
              >
                <span>In-Store</span>
              </button>
            </div>
          </div>

          {loading ? (
            <div className={styles.loadingPage}>
              <Loader />
            </div>
          ) : bannerList ? (
            <div className={styles.promotionBlock}>
              {bannerList?.length > 0 ? (
                bannerList
                  .sort((a, b) => a.sequence - b.sequence)
                  .map((val, i) => (
                    <WebBannerCard
                      id={val.id}
                      image={val.banner}
                      title={val.name}
                      data={{ ...val }}
                      key={i}
                      sequenceNumber={val.sequence}
                    />
                  ))
              ) : (
                <div>
                  <h2 style={{ textAlign: "center" }}>{activeCategory === 1 ? "No Home Banner" : "No In-Store Banner"}</h2>
                </div>
              )}
            </div>
          ) : null}

          {/* {activeCategory === 1 && (
            <div className={styles.promotionBlock}>
              {homeBanner?.length > 0 ? (
                homeBanner.map((val, i) => (
                  <WebBannerCard
                    // id={val.id}
                    image={val.banner}
                    title={val.name}
                    data={{ ...val }}
                    key={i}
                    sequenceNumber={val.sequence}
                  />
                ))
              ) : (
                <div>
                  <h2 style={{ textAlign: "center" }}>No Home Banner</h2>
                </div>
              )}
            </div>
          )}
          {activeCategory === 2 && (
            <div className={styles.promotionBlock}>
              {inStoreBanner?.length > 0 ? (
                inStoreBanner.map((val, i) => (
                  <WebBannerCard
                    // id={val.id}
                    image={val.banner}
                    title={val.name}
                    data={{ ...val }}
                    key={i}
                    sequenceNumber={val.sequence}
                  />
                ))
              ) : (
                <div>
                  <h2 style={{ textAlign: "center" }}>No In-Store Banner</h2>
                </div>
              )}
            </div>
          )} */}
          {/* {activeCategory === 1 && (
            <div className={styles.promotionBlock}>
              {foodToGoList?.length > 0 ? (
                foodToGoList.map((val, i) => (
                  <PromotionCard
                    id={val.id}
                    image={val.image}
                    title={val.name}
                    startDate={val.effective_date}
                    endDate={val.expiration_date}
                    data={{ ...val, category: val.category.id }}
                    key={i}
                    sequenceNumber={val.sequence_number}
                  />
                ))
              ) : (
                <div>
                  <h2 style={{ textAlign: "center" }}>No Web Promotions</h2>
                </div>
              )}
            </div>
          )} */}
        </>
      )}
    </div>
  );
};

export default Banner;
