import { useEffect, useState } from "react";
import { GET_API_URLS } from "../../../../utils/constant";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import styles from "./style.module.scss";
import { PromotionsTable } from "../../../../components/parts";
import { useAuth } from "../../../../context/AuthProvider";
import { Loader } from "../../../../components/ui";

const Promotions = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    jwtInterceoptor
      .get(GET_API_URLS.PROMOTIONS_URL)
      .then((response) => {
        // console.log(response);
        setLoading(false);
        setAuth((prev) => ({ ...prev, promotions: response.data }));
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403) {
          setError("You dont have permission to access this page.");
        } else {
          setError("There was a problem while loading the data.");
        }
        // console.log("Promotions Error 25", error);
      });
  }, [setAuth]);
  return (
    <div className={styles.promotionsContainer}>
      {loading ? (
        <Loader />
      ) : error ? (
        <h3 style={{ textAlign: "center" }}>{error}</h3>
      ) : (
        <PromotionsTable promoData={auth.promotions} />
      )}
    </div>
  );
};

export default Promotions;
