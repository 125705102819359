import React, { useState } from "react";
import jwtInterceoptor from "../../../api/jwtInterceptor";
import { useNavigate } from "react-router-dom";
import { GET_API_URLS } from "../../../utils/constant";
import { CardWrapper } from "../../wrapper";
import Button from "../button";
import styles from "./style.module.scss";
import { FormErrorPopup } from "../../parts/popups";

const BlogCard = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  /* Converting Image URL TO JS File Object */

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const editBlogHandler = (data) => {
    const imgUrlArr = [data.image];

    var promises = imgUrlArr.map(function (img) {
      return toDataURL(img).then((dataUrl) => {
        //  console.log('Here is Base64 Url', dataUrl)
        let fileData = dataURLtoFile(dataUrl, "imageName.jpg");
        //  console.log("Here is JavaScript File Object",fileData)
        return fileData;
      });
    });
    Promise.all(promises).then(function (results) {
      console.log("check", { ...data, image: results[0] });
      navigate("/dashboard/addBlog", {
        state: { ...data, image: results[0] },
      });
    });
    navigate("/dashboard/addBlog", { state: data });
  };

  const deleteBlogHandler = (id) => {
    setIsDeleting(true);
    jwtInterceoptor
      .delete(GET_API_URLS.BLOG_URL + `${id}/`)
      .then((response) => {
        setIsDeleting(false);
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        setIsDeleting(false);
        setError(error.message);
        console.log("Error", error);
      });
  };

  return (
    <>
      <CardWrapper className={styles.blogCardContainer}>
        <div
          style={{ backgroundImage: `url(${props.blog.image})` }}
          className={styles.blogImgContainer}
        ></div>
        <div className={styles.blogContent}>
          <h3>{props.blog.title}</h3>
          <p>{props.blog.content.replace( /(<([^>]+)>)/ig, '')}</p>
          <div className={styles.btnContainer}>
            <Button
              label="Edit"
              secondary
              onClick={() => editBlogHandler(props.blog)}
            />
            <Button
              label={isDeleting ? "Deleting..." : "Delete"}
              primary
              disabled={isDeleting}
              onClick={() => {
                deleteBlogHandler(props.blog.id);
              }}
            />
          </div>
        </div>
      </CardWrapper>
      {error ? (
        <FormErrorPopup
          error={error}
          close={() => setError(false)}
          errorText="Oops! An error occured"
        />
      ) : null}
    </>
  );
};

export default BlogCard;
