import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import { AddNewPromotionForm } from "../../../../components/parts/forms";
import { POST_API_URLS } from "../../../../utils/constant";
import styles from "./style.module.scss";
import { CardWrapper } from "../../../../components/wrapper";
import { FormErrorPopup } from "../../../../components/parts/popups";

const AddPromotion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  console.log("edit data", location.state);

  const addNewPromotionHandler = (data) => {
    setLoading(true);
    let formData = new FormData();
    for (let k in data) {
      formData.append(k, data[k]);
    }

    jwtInterceoptor
      .post(POST_API_URLS.ADD_PROMO_URL, formData)
      .then((response) => {
        console.log(response.data);
        navigate("/dashboard/promotions");
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
        console.log("Error", error);
      });
  };

  const updatePromoHandler = (data) => {
    setLoading(true);
    let formData = new FormData();
    for (let k in data) {
      formData.append(k, data[k]);
    }

    jwtInterceoptor
      .patch(POST_API_URLS.ADD_PROMO_URL + `${data.id}/`, formData)
      .then((response) => {
        console.log(response.data);
        navigate("/dashboard/promotions");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403) {
          setError("You dont have permission to add promotions.");
        } else {
          setError(error.message);
        }
        console.log("Error", error);
      });
  };

  return (
    <>
      <div className={styles.addPromotionContainer}>
        <CardWrapper>
          <AddNewPromotionForm
            updateData={updatePromoHandler}
            data={addNewPromotionHandler}
            loading={loading}
            formInitialValues={location.state || null}
          />
        </CardWrapper>
      </div>
      {error && <FormErrorPopup error={error} close={() => setError(false)} />}
    </>
  );
};

export default AddPromotion;
