import { useEffect, useState } from "react";
import { GET_API_URLS } from "../../../../utils/constant";
import jwtInterceoptor from "../../../../api/jwtInterceptor";
import styles from "./style.module.scss";
import { BlogCard, Loader } from "../../../../components/ui";
import { useAuth } from "../../../../context/AuthProvider";

const CommunityCare = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    jwtInterceoptor
      .get(GET_API_URLS.BLOG_URL)
      .then((response) => {
        // console.log(response);
        setLoading(false);
        setAuth((prev) => ({ ...prev, blogs: response.data }));
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403) {
          setError("You dont have permission to access this page.");
        } else {
          setError("There was a problem while loading the data.");
        }
        console.log("Error", error);
      });
  }, [setAuth]);

  return (
    <div className={styles.blogContainer}>
      {loading ? (
        <Loader />
      ) : error ? (
        <h3 style={{ textAlign: "center" }}>{error}</h3>
      ) : (
        <div className={styles.blogInnerBlock}>
          {auth.blogs.map((blog, i) => {
            return <BlogCard blog={blog} key={i} />;
          })}
        </div>
      )}
    </div>
  );
};

export default CommunityCare;
