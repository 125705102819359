import React, { useState } from "react";
import GoogleMap from "../../wrapper/googleMap";
import { Loader, Marker } from "../../ui";

const Map = (props) => {
  const [infoWindowData, setInfoWindowData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const defaultCenter = { lat: 50.98730738476, lng: -113.99863406137 };
  const defaultZoom = 5;
  const mapLoaderStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "700px",
  };

  if (!props.locationData) {
    return (
      <div style={mapLoaderStyle}>
        <Loader />
      </div>
    );
  } else if (props.error) {
    return <h3 style={{ textAlign: "center" }}>{props.locationData}</h3>;
  } else {
    const coordinatesArr =
      props.locationData &&
      props.locationData.map((data) => {
        return {
          id: data.id,
          name: data.name,
          address: data.address,
          city: data.city,
          province: data.province,
          latitude: data.latitude,
          longitude: data.longitude,
        };
      });

    function markerClickHandler(id, name, address, city, province) {
      setInfoWindowData({ id, address, name, city, province });
      setIsOpen(true);
    }

    console.log("co-ord", infoWindowData);
    return (
      <GoogleMap defaultCenter={defaultCenter} defaultZoom={defaultZoom}>
        {coordinatesArr &&
          coordinatesArr.map((loc, i) => (
            <Marker
              key={i}
              data={loc}
              lat={loc.latitude}
              lng={loc.longitude}
              onMarkerClick={(id, name, address, city, province) =>
                markerClickHandler(id, name, address, city, province)
              }
              infoWindowData={infoWindowData}
              isOpen={isOpen}
            />
          ))}
      </GoogleMap>
    );
  }
};

export default Map;
