import React from "react";
import GoogleMapReact from "google-map-react";

const GoogleMap = ({ children, ...props }) => {
  return (
    <div style={{ width: "100%", height: "60vh" }}>
      <GoogleMapReact
        resetBoundsOnResize
        bootstrapURLKeys={{
          key: "AIzaSyBxCE0TzdY3j6Y3sGZ4Yh8SSQV8TRxTaBw",
        }}
        {...props}
      >
        {children ? children : []}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
