import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import jwtInterceoptor from "../../../api/jwtInterceptor";
import { GET_API_URLS } from "../../../utils/constant";
import Loader from "../../ui/loader";
/* Importing Stylesheets */
import styles from "./style.module.scss";
/* Importing Images */
import CancoLogo from "../../../assets/cancoLogo.png";
// import { GeoAlt, People, Trophy, ChatSquareText } from "react-bootstrap-icons";

const Sidebar = () => {
  const [loading, setLoading] = useState(true);
  const [modulesData, setModuleData] = useState(null);
  const { userData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData.modules.length > 0) {
      jwtInterceoptor
        .get(GET_API_URLS.MODULE)
        .then((response) => {
          console.log(response.data);
          setLoading(false);
          comparingModuleHandler(response.data, userData.modules);
        })
        .catch((error) => {
          setLoading(false);
          console.log("Sidebar Error", error);
        });
    }
  }, [userData]);

  function comparingModuleHandler(moduleData, userData) {
    let ids = userData.map((val) => val.id);
    const allowedModules = moduleData.filter(
      ({ id }) => ids.includes(id) && id !== 8
    );
    setModuleData(allowedModules);
  }

  // console.log("module data 34", { modulesData, userData });

  return (
    <aside className={styles.sidebarContainer}>
      <figure onClick={() => navigate("/dashboard")}>
        <img src={CancoLogo} alt="Canco Logo" />
      </figure>
      <ul>
        {loading ? (
          <li className={styles.loaderContainer}>
            <Loader />
          </li>
        ) : (
          modulesData &&
          modulesData.map((item, i) => {
            let navLink =
              item.id === 1
                ? "locations"
                : item.id === 2
                ? "subAdmin"
                : item.id === 3
                ? "promotions"
                : item.id === 4
                ? "communityCare"
                : item.id === 5
                ? "webPromotions"
                : item.id === 7
                ? "banner"
                : null;
            return (
              <li key={i}>
                <NavLink to={navLink}>
                  {/* <img src={item.icon} alt="canco admin sidebar menu item"/> */}
                  {item.name}
                </NavLink>
              </li>
            );
          })
        )}
      </ul>
      {/* <ul>
        <li>
          <NavLink to={"locations"}>
            <GeoAlt />
            Locations
          </NavLink>
        </li>
        <li>
          <NavLink to={"subAdmin"}>
            <People />
            Sub Admin
          </NavLink>
        </li>
        <li>
          <NavLink to={"promotions"}>
            <Trophy />
            Promotions
          </NavLink>
        </li>
        <li>
          <NavLink to={"communityCare"}>
            <ChatSquareText />
            Community Care
          </NavLink>
        </li>
      </ul> */}
    </aside>
  );
};

export default Sidebar;
