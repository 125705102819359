import axios from "axios";

const jwtInterceoptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

jwtInterceoptor.interceptors.request.use((config) => {
  let tokensData = JSON.parse(localStorage.getItem("tokens"));
  config.headers["Authorization"] = `Bearer ${tokensData.access}`;
  return config;
});

jwtInterceoptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      const authData = JSON.parse(localStorage.getItem("tokens"));
      const payload = {
        refresh: authData.refresh,
      };
      // console.log('payload', authData);
      let apiResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/accounts/login/refresh/`, payload);
      localStorage.setItem("tokens", JSON.stringify({ ...apiResponse.data, refresh: authData.refresh }));
      error.config.headers["Authorization"] = `Bearer ${apiResponse.data.access}`;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

export default jwtInterceoptor;
