import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtInterceoptor from "../../../api/jwtInterceptor";
import { GET_API_URLS } from "../../../utils/constant";
import { FormErrorPopup } from "../../parts/popups";
import Button from "../button";
import styles from "./style.module.scss";
import Ribbon from "../ribbon";

const WebBannerCard = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const editBannerHandler = (data) => {
    navigate("/dashboard/addBanner", { state: data });
  };

  const deleteBannerHandler = (id) => {
    setIsDeleting(true);
    jwtInterceoptor
      .delete(GET_API_URLS.BANNERS + `${id}/`)
      .then((response) => {
        setIsDeleting(false);
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        setIsDeleting(false);
        setError(error.message);
        console.log("Error", error);
      });
  };

  return (
    <>
      <div className={styles.promotionCard}>
        <div className={styles.promotionImage}>
          <img src={props.image} alt="Promotion" />
          {props.data.featured_banner && <Ribbon title="Featured" />}
        </div>
        <div className={styles.promotionContent}>
          <div className={styles.promotionInfo}>
            <div className={styles.promotionDate}>
              <h2>{props.title || "Banner"}</h2>
            </div>
            <div className={styles.sequence}>
              <span>{props.sequenceNumber}</span>
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button
            label="Edit"
            secondary
            onClick={() => editBannerHandler(props.data)}
          />
          <Button
            label={isDeleting ? "Deleting..." : "Delete"}
            primary
            disabled={isDeleting}
            onClick={() => {
              deleteBannerHandler(props.id);
            }}
          />
        </div>
      </div>
      {error ? (
        <FormErrorPopup
          error={error}
          close={() => setError(false)}
          errorText="Oops! An error occured"
        />
      ) : null}
    </>
  );
};

export default WebBannerCard;
